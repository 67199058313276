import { useAppDispatch, useAppSelector } from '../store';
import { getCurrentThread } from './selectors';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ERROR_MESSAGES, MOOV_API_URL } from '../constants';
import { Listing2 } from '../listings/types';
import { openError } from '../state/banner/slice';

export const useFetchCurrentMessageListing = () => {
  const currentThread = useAppSelector(getCurrentThread);
  const dispatch = useAppDispatch();
  const [listing, setListing] = useState<Listing2 | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!currentThread.key) {
        setListing(null);
        return;
      }
      setLoading(true);
      setListing(null);
      await axios
        .get(`${MOOV_API_URL}/listings/${currentThread.listing_info.key}`, {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          withCredentials: true
        })
        .then((resp) => {
          setListing(Listing2.anyToDto(resp.data.data));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          dispatch(openError({ error: ERROR_MESSAGES.REFRESH }));
        });
    })();
  }, [currentThread.key]);

  return { listing, listingLoading: loading };
};
