import { FC, useEffect } from 'react';
import { AuthMakeOfferModal } from './authMakeOffer';
import { AnonMakeOfferModal } from './anonMakeOffer';
import { userIsLoggedIn } from '@/src/state/user/selectors';
import { useAppSelector } from '@/src/store';
import { MakeOffer } from './makeOffer';
import { BooleanFlag, useBooleanFeatureFlag } from '@/src/hooks/featureFlags';
import { LoginModal } from '../../login';
import { ListingMessageSellerCTAQueryParam } from '@/src/marketplace/listingV2/types';
import { useRouter } from 'next/router';

export const MakeOfferModal: FC = () => {
  const isLoggedIn = useAppSelector(userIsLoggedIn);
  const makeOfferOn = useBooleanFeatureFlag(BooleanFlag.MakeOffer);
  const router = useRouter();
  useEffect(() => {
    //Only add the cta if make offer FF is on and they aren't logged in.
    //We do not gate logging in for the old flow.
    if (makeOfferOn && !isLoggedIn) router.replace({ query: { ...router.query, cta: ListingMessageSellerCTAQueryParam.MAKE_OFFER } });
  }, []);

  //New make offer flow
  if (makeOfferOn) {
    if (!isLoggedIn) {
      return <LoginModal />;
    }
    return <MakeOffer />;
  }

  //Old deprecated flow, this will be deleted when the new flow is fully implemented
  return isLoggedIn ? <AuthMakeOfferModal /> : <AnonMakeOfferModal />;
};
