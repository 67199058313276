import { borderRadius, colors, spacing } from '@/src/design/designConstants';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { getCurrentThread } from './selectors';
import { useAppSelector } from '@/src/store';
import { SenderChip } from './senderChip';
import { formatShortDate } from './utils';
import { useAppDispatch } from '@/src/store';
import { createMessageForCurrentThread, markCurrentMessageThreadAsRead } from './thunks';
import { useTimeout } from '../hooks/useTimeout';
import { useFullStory } from '../hooks/useFullStory/useFullStory';
import { typedMessageEvent } from '../state/tracking/fullstory/customEvents/messages';
import { MessagePolicyTypography } from './messagePolicyTypography';
import { useResizeObserver } from '../hooks/useResizeObserver';

export const MessageChat = () => {
  const [newMessage, setNewMessage] = useState('');
  const currentThread = useAppSelector(getCurrentThread);
  const dispatch = useAppDispatch();
  const { setTO, clearTO } = useTimeout();
  const { fullStoryAndGTMEvent } = useFullStory();
  const { setObserver } = useResizeObserver();

  useEffect(() => {
    const hasUnReads = currentThread.messages.some((message) => !message.seen);
    clearTO();
    if (hasUnReads) {
      setTO(() => {
        dispatch(markCurrentMessageThreadAsRead());
      }, 2000);
    }
  }, [currentThread.key]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;
    // Use resize observer to scroll to bottom of chat
    // on load, new messages or threads change
    setObserver(containerRef, () => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    });
  }, [containerRef.current, currentThread.messages.length, currentThread.key]);

  return (
    <Stack
      data-testid="messaging-center-chat"
      bgcolor={colors.surface.white}
      height="100%"
      justifyContent={'space-between'}
      px={spacing.xl}
      pb={spacing.xl}
    >
      <Stack sx={{ overflowY: 'auto' }} ref={containerRef}>
        {currentThread.messages.map((message) => {
          const { body, created_at, current_user_is_sender } = message;
          const alignSelf = current_user_is_sender ? 'flex-end' : 'flex-start';
          return (
            <Stack
              key={`message-page-chat-${message.key}-${message.created_at}`}
              spacing={spacing.md}
              py={spacing.xl}
              alignSelf={alignSelf}
            >
              <Stack alignSelf={alignSelf} spacing={spacing.md} direction="row" alignItems={'center'}>
                <SenderChip message={message} />
                <Typography color={colors.smokeyGray['400']} variant="caption">
                  {formatShortDate(created_at)}
                </Typography>
              </Stack>
              <Typography
                borderRadius={borderRadius.roundedLg}
                padding={spacing.lg}
                bgcolor={current_user_is_sender ? colors.moovBlue['500'] : colors.magenta['100']}
                color={current_user_is_sender ? colors.surface.white : colors.moovBlue['500']}
                variant="body2"
                fontWeight="400"
              >
                {body}
              </Typography>
            </Stack>
          );
        })}
      </Stack>

      <Stack spacing={spacing.xl}>
        <Stack spacing={spacing.sm}>
          <TextField
            placeholder="Type a message here"
            multiline
            minRows={2}
            maxRows={2}
            value={newMessage}
            onChange={(e) => setNewMessage(e.currentTarget.value)}
          />
          <MessagePolicyTypography />
        </Stack>
        <Button
          sx={{ height: '56px' }}
          onClick={() => {
            dispatch(createMessageForCurrentThread(newMessage));
            fullStoryAndGTMEvent(typedMessageEvent.messagingSendMessage, {
              message: newMessage,
              opportunityKey: currentThread.key
            });
            setNewMessage('');
          }}
          disabled={!currentThread.key || !newMessage}
          variant="contained"
          color="primary"
        >
          SEND MESSAGE
        </Button>
      </Stack>
    </Stack>
  );
};
