import axios from 'axios';
import { AppDispatch, AppState } from '../store';
import { ERROR_MESSAGES, MOOV_API_URL } from '../constants';
import { AddressSerializable, valueFromKey } from '../common/lib';
import { captureException } from '@sentry/nextjs';
import { BuyerOffer, ReadableOfferStatus, SellerOffer, SellerOfferGroup, SellerOfferGroupSerializable } from './types';
import {
  loadBuyerOffers,
  loadSellerOffers,
  setError,
  setLoading,
  setStatusFilter,
  updateBuyerOffer,
  updateSellerOfferGroup
} from './slice';
import { openError } from '../state/banner/slice';

export interface MakeOfferPayload {
  offer_amount: number;
  destination_address: AddressSerializable;
  offer_note: string;
  offer_subject_to: boolean;
  offer_subject_to_note: string;
  required_by: string;
  terms_and_conditions: boolean;
}

const _createOffer = async (dispatch: AppDispatch, { body, listingKey }: { body: MakeOfferPayload; listingKey: string }) => {
  return axios
    .post<void>(
      `${MOOV_API_URL}/auth/offers/listing/${listingKey}`,
      { ...body },
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      }
    )
    .then(() => {
      // TODO: ENG-2362 put offer in store and show "Pending Offer" on listing page
    });
};

const _updateOffer = async (dispatch: AppDispatch, { body, offerKey }: { body: MakeOfferPayload; offerKey: string }) => {
  return axios
    .put<void>(
      `${MOOV_API_URL}/auth/offers/buyer/${offerKey}`,
      { ...body },
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      }
    )
    .then((resp) => {
      const offer = BuyerOffer.anyToSerializable(valueFromKey('data', resp.data));
      dispatch(updateBuyerOffer({ offer, oldOfferKey: offerKey }));
    });
};

export function createOrUpdateOffer({ body, listingKey, offerKey }: { body: MakeOfferPayload; listingKey: string; offerKey?: string }) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    if (typeof offerKey === 'string') {
      return _updateOffer(dispatch, { body, offerKey });
    }
    return _createOffer(dispatch, { body, listingKey });
  };
}

export function fetchBuyerOffers() {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    dispatch(setLoading());
    return axios
      .get<void>(`${MOOV_API_URL}/auth/offers/buyer`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        const body = valueFromKey<Array<any>>('data', resp.data);

        dispatch(loadBuyerOffers(body.map((of) => BuyerOffer.anyToSerializable(of))));
      })
      .catch((e) => {
        dispatch(setError());
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
}

export function fetchSellerOffers() {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    dispatch(setLoading());

    return axios
      .get<void>(`${MOOV_API_URL}/auth/offers/seller`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        const body = valueFromKey<Array<any>>('data', resp.data);
        dispatch(loadSellerOffers(body.map((of) => SellerOfferGroup.anyToSerializable(of))));
      })
      .catch((e) => {
        dispatch(setError());
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
}

export function acceptOffer(offer: SellerOffer) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .put<void>(`${MOOV_API_URL}/auth/offers/seller/${offer.key}/accept`, undefined, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        const body = valueFromKey<SellerOfferGroupSerializable>('data', resp.data);
        dispatch(updateSellerOfferGroup(SellerOfferGroup.anyToSerializable(body)));
        //Set the filter to accepted so the offer is still on the page
        dispatch(setStatusFilter(ReadableOfferStatus.ACCEPTED));
      });
  };
}

export function declineOffer(offer: SellerOffer, reason: string) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .put<void>(
        `${MOOV_API_URL}/auth/offers/seller/${offer.key}/decline`,
        { reason },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          withCredentials: true
        }
      )
      .then((resp) => {
        const body = valueFromKey<SellerOfferGroupSerializable>('data', resp.data);
        dispatch(updateSellerOfferGroup(SellerOfferGroup.anyToSerializable(body)));
      });
  };
}
