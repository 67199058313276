import { Checkbox, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { ChangeEvent, FC, FocusEventHandler, ReactNode } from 'react';
import { CheckboxByteOnChangeHandler } from './types';

export interface CheckboxByteProps extends Omit<FormControlLabelProps, 'onChange' | 'onBlur' | 'control'> {
  readonly 'data-testid'?: string;
  readonly label: ReactNode;
  readonly checked: boolean;
  readonly value?: number;
  readonly disabled?: boolean;
  readonly onChange: CheckboxByteOnChangeHandler;
  readonly onBlur?: FocusEventHandler<HTMLButtonElement>;
  readonly required?: boolean;
}

export const CheckboxByte: FC<CheckboxByteProps> = ({ label, disabled, checked, value, onChange, onBlur, required, ...rest }) => (
  <FormControlLabel
    {...rest}
    control={<Checkbox onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) => onChange(checked, value)} onBlur={onBlur} />}
    required={required}
    disabled={disabled}
    label={label}
    checked={checked}
    value={value}
    data-testid={rest['data-testid']}
  />
);
