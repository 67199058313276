import { userIsLoggedIn } from '@/src/state/user/selectors';
import { useAppSelector } from '@/src/store';
import { LoginModal } from '../login';
import { MessageSellerModal } from './messageSellerModal';

export const MessageSeller = () => {
  const isLoggedIn = useAppSelector(userIsLoggedIn);

  if (!isLoggedIn) {
    return <LoginModal />;
  }

  return <MessageSellerModal />;
};
