import dayjs from 'dayjs';
import { ReadableOfferStatus } from './types';

export const getStatusColor = (status: ReadableOfferStatus) => {
  switch (status) {
    case ReadableOfferStatus.PENDING:
      return 'primary';
    case ReadableOfferStatus.ACCEPTED:
      return 'success';
    case ReadableOfferStatus.DECLINED:
      return 'error';
    case ReadableOfferStatus.EXPIRED:
      return 'warning';
  }
};

export const formatStatusTimeDifference = ({ status_at }: { status_at: string }) => {
  const now = dayjs();
  const statusAt = dayjs(status_at);
  const hoursDiff = now.diff(statusAt, 'hours');
  let secondsDiff = now.diff(statusAt, 'seconds');

  let formattedDiff;

  if (secondsDiff < 60) {
    if (secondsDiff === 0) secondsDiff++;
    formattedDiff = `${secondsDiff} SEC${secondsDiff > 1 ? 'S' : ''} AGO`;
  } else if (hoursDiff < 1) {
    const minutesDiff = now.diff(statusAt, 'minutes');
    formattedDiff = `${minutesDiff} MIN${minutesDiff > 1 ? 'S' : ''} AGO`;
  } else if (hoursDiff < 24) {
    formattedDiff = `${hoursDiff} HR${hoursDiff > 1 ? 'S' : ''} AGO`;
  } else {
    const daysDiff = now.diff(statusAt, 'days');
    formattedDiff = `${daysDiff} DAY${daysDiff > 1 ? 'S' : ''} AGO`;
  }

  return formattedDiff;
};
