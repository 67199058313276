import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { MOOV_API_URL } from '../../constants';
import { CreateUserSession, User } from '../../entities/user/types';
import { fetchUser } from '../../providers/users';
import routes from '../../routes';
import { useAppDispatch } from '../../store';
import { loadUser, fetchUser as fetchUserAction, loadUserError, loadViewerCountryCode, userUnauthenticated } from '../../state/user/slice';

/**
 * TODO #182874605 {MOOVDEV-2081} verify throw caught by SWR `error` value
 *
 * Global, cached hook.
 * @deprecated
 * Ticket to clean up this hook and move everything to redux: ENG-2445
 * Use selectors from the users slice
 */
export const useUser = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const { data, error, mutate } = useSWR(
    `${MOOV_API_URL}/user`,
    () => {
      dispatch(fetchUserAction());
      return fetchUser()
        .then((data) => {
          dispatch(loadViewerCountryCode(data.viewerCountryCode));
          // If the user is null, they are not logged in
          data.user ? dispatch(loadUser(data.user)) : dispatch(userUnauthenticated());
          return data.user;
        })
        .catch((err) => {
          captureException(err, {
            tags: {
              component: 'useUser',
              file: 'src/hooks.ts'
            }
          });
          dispatch(loadUserError());
          throw err;
        });
    },
    {
      refreshInterval: 1000 * 60 * 60,
      dedupingInterval: 1000 * 3,
      refreshWhenHidden: true,
      refreshWhenOffline: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: false
    }
  );

  return {
    user: data,
    userIsLoggedIn: (value: User | null | undefined): value is User => value !== null && typeof value !== 'undefined',
    userIsInternal: (value: User | null | undefined): value is User => !!value && value.role >= 10,
    userIsBlacklisted: (value: User): value is User => value.role == 3,
    refreshUser: mutate,
    userError: error,
    userIsLoading: data === undefined && !error,
    loaded: data !== undefined && !error,
    loginUser: async (payload: CreateUserSession): Promise<void> => {
      try {
        const { data } = await axios.post<{ attributes: User }>(`${MOOV_API_URL}/login`, payload, {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          withCredentials: true
        });

        // DO NOT REMOVE THIS
        // if we wait for mutate to finish w/o using the response here
        // there's race conditions with components that use useUser and redux
        dispatch(loadUser(data.attributes));
        mutate(data.attributes);
      } catch (err) {
        captureException(err, {
          tags: {
            hook: 'useUser',
            file: 'src/hooks/useUser/index.tsx'
          }
        });
        throw err;
      } finally {
        dispatch({ type: 'root/reset' });
      }
    },
    logoutUser: async (): Promise<void> => {
      try {
        await axios.post(
          `${MOOV_API_URL}/logout`,
          {},
          {
            headers: { 'Content-Type': 'application/vnd.api+json' },
            withCredentials: true
          }
        );
      } catch (err) {
        captureException(err, {
          tags: {
            hook: 'useUser',
            file: 'src/hooks/useUser/index.tsx'
          }
        });
        // TODO #182874605 {MOOVDEV-2081} implement error reporting to UI
      } finally {
        dispatch({ type: 'root/reset' });
        router.push({ pathname: routes.root() });
        await mutate(null);
      }
    }
  };
};
