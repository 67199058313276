import OLDLoginModal from '@/src/bits/modals/loginModal';
import { SetModal } from '@/src/entities/common';
import { closeModal } from '@/src/state/globalModal/slice';
import { useAppDispatch } from '@/src/store';
import { useState } from 'react';

export const LoginModal = () => {
  const dispatch = useAppDispatch();

  const [modal, setModal] = useState<JSX.Element | null>(null);

  if (modal) {
    return modal;
  }

  const setModalProp: SetModal = (el) => {
    if (el) {
      // DO NOT REMOVE THIS, this login modal was never meant to use in the global space
      // it uses this setModal function to close OR set the reset password modal.
      // We need to refactor our login modal to actually use the global modal
      setModal(el);
    } else {
      dispatch(closeModal());
    }
  };

  return <OLDLoginModal setModal={setModalProp} />;
};
