export const typedMessageEvent = {
  messagingSendMessage: 'messaging-send-message',
  messagingCancelMessage: 'messaging-cancel-message',
  messagingOpen: 'messaging-open'
} as const;

export enum MESSAGING_LINK_TYPES {
  MESSAGE_CENTER = 'message-center',
  MESSAGE_SIDE_DRAWER = 'message-side-drawer'
}

type SendMessageFromMessageCenterPage = [typeof typedMessageEvent.messagingSendMessage, { message: string; opportunityKey: string }];
type SendMessageFromListingPage = [typeof typedMessageEvent.messagingSendMessage, { message: string; listingKey: string }];
type SendMessageForOffer = [typeof typedMessageEvent.messagingSendMessage, { message: string; offerKey: string }];
type DisMissSendingMessage = [typeof typedMessageEvent.messagingCancelMessage, { listingKey: string }];
type MessagingOpen = [typeof typedMessageEvent.messagingOpen, { location: MESSAGING_LINK_TYPES }];

export type MessageTypes =
  | SendMessageFromMessageCenterPage
  | SendMessageFromListingPage
  | DisMissSendingMessage
  | MessagingOpen
  | SendMessageForOffer;
