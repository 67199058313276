// Things in here are not especially semantically named, but we can clean up
// afterwards as long as everything is referencing this file
// May make a provider etc, but to start we're keeping it small and easy to refactor

export const colors = {
  brand: {
    50: '#F6F9FC',
    200: '#BCD7E4',
    500: '#234454'
  },
  yellow: {
    50: '#FEF9EB',
    100: '#FDF1D3'
  },
  orange: {
    50: '#FEF7F1',
    100: '#FDEFE2',
    200: '#FBDCC1',
    300: '#F9C69A',
    400: '#F6AB6A',
    500: '#F28C34',
    600: '#EB760F',
    700: '#CE670D',
    800: '#A8540B',
    900: '#7D3E08',
    950: '#5B2E06'
  },
  magenta: {
    50: '#FDF2F9',
    100: '#FAE5F3',
    200: '#F4C8E5',
    300: '#EEA5D5',
    400: '#E472BE',
    500: '#C72391',
    600: '#B21F81',
    700: '#9B2070'
  },
  corpGray: {
    50: '#F6F8F9',
    100: '#EDF1F2',
    200: '#D8E0E4',
    300: '#C6D1D7',
    400: '#ABBCC4',
    500: '#8FA5B0',
    600: '#7E98A3',
    800: '#3D4E57'
  },
  smokeyGray: {
    50: '#FAFAFA',
    100: '#ECEEEF',
    200: '#E2E4E7',
    300: '#BDBFC2',
    400: '#979B9F',
    500: '#606770',
    600: '#575D66',
    800: '#3B3F45'
  },
  moovBlue: {
    50: '#F6F9FC',
    100: '#EAF4F8',
    200: '#BCD7E4',
    300: '#89B7CD',
    400: '#4A8FAF',
    500: '#234454',
    600: '#1F3E4C',
    800: '#152932'
  },
  crayolaGreen: {
    50: '#EBF9F4',
    500: '#1DA370',
    600: '#178259',
    700: '#126444'
  },
  skyBlue: {
    50: '#E3F8FF',
    500: '#23C4F8',
    600: '#07A5D9'
  },
  notification: {
    crayolaGreen: {
      50: '#EBF9F4',
      500: '#1DA370',
      600: '#178259',
      700: '#126444'
    },
    hotRed: {
      50: '#FFF1F1',
      500: '#FD385D'
    }
  },
  logo: {
    // TODO: 30% opacity colors?
    turquoise: '#6EBDCD',
    teal: '#479DAA',
    emerald: '#5FAF86',
    lime: '#9ECB57',
    yellow: '#E3D640',
    gold: '#F4C23A',
    orange: '#F28C34',
    salmon: '#EB737E',
    plum: '#CA64A5',
    skyBlue: '#23C4F8'
  },
  surface: {
    black: '#000',
    transparent: 'transparent',
    white: '#FFFFFF',
    moovBlue50: '#F6F9FC',
    moovBlue100: '#EAF4F8',
    magenta50: '#FDF2F9',
    magentaGradient: 'linear-gradient(90deg, rgba(199, 35, 145) 0%, rgba(253, 56, 93) 100%)',
    smokeyGray50: '#FAFAFA',
    dangerRed: ' #DC3545'
  },
  Legacy: {
    corpPurple: '#834B70',
    corpTeal: '#5186A0',
    corpGold: '#D1A83C'
  }
} as const;

export const heights = {
  small: '32px',
  medium: '40px',
  large: '56px'
} as const;

export type size = 'small' | 'medium' | 'large';

export const fontFamily = {
  lato: '"Lato", sans-serif',
  inter: '"Inter", sans-serif',
  /**
   * @deprecated All new things should be lato or inter
   */
  montserrat: '"Montserrat", sans-serif',

  /**
   * @deprecated All new things should be lato or inter
   */
  helvetica: '"Helvetica", sans-serif'
} as const;

/**
 * This one feels particularly stupid, but until we have something
 * more semantic we at least have this
 */
export const fontWeight = {
  400: '400',
  500: '500',
  600: '600',
  700: '700'
} as const;

/**
 * This one, white not _quite_ as stupid, is still stupid, but I'm
 * struggling with how you would even make this semantic
 */
export const fontSize = {
  '9spx': '.5625rem',
  '10spx': '.625rem',
  '11spx': '.6875rem',
  '12spx': '.75rem',
  '13spx': '.8125rem',
  '14spx': '.875rem',
  '15spx': '.9375rem',
  '16spx': '1rem',
  '17spx': '1.0625rem',
  '18spx': '1.125rem',
  '20spx': '1.25rem',
  '21spx': '1.3125rem',
  '24spx': '1.5rem',
  '30spx': '1.875rem',
  '32spx': '2rem',
  '34spx': '2.125rem',
  '36spx': '2.25rem',
  '40spx': '2.5rem',
  '48spx': '3rem',
  '60spx': '3.75rem',
  '64spx': '4rem',
  '72spx': '4.5rem',
  '96spx': '6rem'
} as const;

export const spacing = {
  none: '0px',
  xxs: '2px',
  xs: '4px',
  sm: '6px',
  md: '8px',
  lg: '12px',
  xl: '16px',
  '2xl': '20px',
  '3xl': '24px',
  '4xl': '32px',
  '5xl': '40px',
  '6xl': '48px',
  '7xl': '64px',
  '8xl': '80px',
  '9xl': '96px',
  '10xl': '128px',
  '11xl': '160px'
} as const;
export type DesignSpacing = (typeof spacing)[keyof typeof spacing];

export const borderRadius = {
  roundedNone: '0px',
  roundedSm: '2px',
  rounded: '4px',
  roundedMd: '6px',
  roundedLg: '8px',
  roundedXl: '12px',
  rounded2xl: '16px',
  rounded3xl: '24px',
  roundedFull: '9999px'
} as const;

export const breakpoints = {
  xs: 0,
  d: 1024
};

export const FULLSTORY_EXCLUDE_DATA_CAPTURE_CLASSNAME = 'fs-exclude';
