import { getModalState } from '@/src/state/globalModal/selectors';
import { closeModal } from '@/src/state/globalModal/slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { Stack } from '@mui/material';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { FC, useState } from 'react';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { spacing } from '@/src/design/designConstants';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { createMessageFromOfferForSeller } from '@/src/messages/thunks';
import { MessagePolicyTypography } from '@/src/messages/messagePolicyTypography';
import { captureException } from '@sentry/nextjs';
import { openError } from '@/src/state/banner/slice';
import { ERROR_MESSAGES } from '@/src/constants';
import { typedMessageEvent } from '@/src/state/tracking/fullstory/customEvents/messages';

export const MessageBuyerFromOffer: FC = () => {
  const dispatch = useAppDispatch();
  const {
    modalProps: { messageBuyerFromOffer }
  } = useAppSelector(getModalState);
  const [message, setMessage] = useState('');
  const { openSuccessMessage } = useGlobalAlertBanner();
  const { fullStoryAndGTMEvent } = useFullStory();

  if (!messageBuyerFromOffer) return null;

  const sendMessage = async () => {
    fullStoryAndGTMEvent(typedMessageEvent.messagingSendMessage, { message, offerKey: messageBuyerFromOffer.offerKey });
    return dispatch(createMessageFromOfferForSeller({ body: message, offerKey: messageBuyerFromOffer.offerKey }))
      .then(() => {
        openSuccessMessage('Message sent!');
        onClose();
      })
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <WorkflowModal
      // TODO: translations Send a Message
      title={'Send a Message'}
      modalOpen={true}
      currentStep={0}
      totalSteps={1}
      closeModal={() => {
        dispatch(closeModal());
      }}
      setCurrentStep={() => {}}
      actionButtonContent={'SEND MESSAGE'}
      actionButtonSubmit={sendMessage}
      dismissButtonContent={'CANCEL'}
      dismissButtonSubmit={async () => {
        onClose();
      }}
      actionButtonDisabled={message.length === 0}
      displayBackButton={false}
      actionButtonId="message-send-message-for-offer"
      dismissButtonId="message-dismiss-sending-message-for-offer"
    >
      <Stack width={{ d: '600px' }} spacing={spacing.sm} data-testid="message-buyer-from-offer-modal-content">
        <LabeledTextField
          value={message}
          label={'Message'}
          onChange={(e) => setMessage(e.currentTarget.value)}
          multiline
          minRows={3}
          maxRows={6}
        />
        <MessagePolicyTypography />
      </Stack>
    </WorkflowModal>
  );
};
