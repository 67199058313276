import { FC } from 'react';
import { ReadableOfferStatus, SellerOfferGroup } from '../types';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { colors, spacing } from '@/src/design/designConstants';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';

export const DeclinedExpiredOfferSummary: FC<{ sellerOfferGroup: SellerOfferGroup }> = ({ sellerOfferGroup }) => {
  const { palette } = useTheme();

  const everyOfferExpired = sellerOfferGroup.all_offers.every((offer) => offer.readable_status === ReadableOfferStatus.EXPIRED);
  const everyOfferDeclined = sellerOfferGroup.all_offers.every((offer) => offer.readable_status === ReadableOfferStatus.DECLINED);

  let topLevelStatus = 'Declined/Expired';

  if (everyOfferExpired) {
    topLevelStatus = 'Expired';
  } else if (everyOfferDeclined) {
    topLevelStatus = 'Declined';
  }

  return (
    <>
      <Paper
        sx={{
          py: spacing.md,
          px: spacing.lg,
          border: `1px solid ${palette.error.light}`,
          background: palette.error.lighter,
          color: palette.error.main
        }}
        variant="outlined"
      >
        <Stack direction="row" height="100%" alignItems="center" spacing={spacing.md}>
          <FAIcon icon={icons.circle} color={colors.notification.hotRed['500']} />
          <Typography variant="p14">
            <strong>{topLevelStatus}</strong>
          </Typography>
        </Stack>
      </Paper>
    </>
  );
};
