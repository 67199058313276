import { Stack } from '@mui/material';
import { SelectWithOptions } from '../../design/bytes/selectWithOptions';
import { useAppDispatch, useAppSelector } from '../../store';
import { getBuyerOffers, getOfferFilters } from '../selectors';
import { ReadableOfferStatus } from '../types';
import { setModelFilter, setStatusFilter } from '../slice';
import { spacing } from '../../design/designConstants';
import { toRecord } from '../../state/services';

export const BuyerOfferFilters = () => {
  const { status, modelId } = useAppSelector(getOfferFilters);
  const dispatch = useAppDispatch();
  const offers = useAppSelector(getBuyerOffers);

  const uniqueModels = Object.values(toRecord(offers.map((offer) => offer.listing.model)));

  return (
    <Stack direction="row" px={spacing.xl} spacing={spacing.lg}>
      <SelectWithOptions
        sx={{ minWidth: '240px' }}
        value={modelId}
        options={[{ id: '0', label: 'All Models' }, ...uniqueModels.map((model) => ({ id: model.id.toString(), label: model.name }))]}
        handleChange={(newValue) => {
          dispatch(setModelFilter(newValue));
        }}
      />
      <SelectWithOptions
        sx={{ minWidth: '240px' }}
        value={status}
        renderValue={(value) => {
          if (value === 'all') {
            return 'All Statuses';
          }

          return `Status: ${value}`;
        }}
        options={[
          { id: 'all', label: 'All Statuses' },
          { id: ReadableOfferStatus.PENDING, label: ReadableOfferStatus.PENDING },
          { id: ReadableOfferStatus.ACCEPTED, label: ReadableOfferStatus.ACCEPTED },
          { id: ReadableOfferStatus.DECLINED, label: ReadableOfferStatus.DECLINED },
          { id: ReadableOfferStatus.EXPIRED, label: ReadableOfferStatus.EXPIRED }
        ]}
        handleChange={(newValue) => {
          dispatch(setStatusFilter(newValue));
        }}
      />
    </Stack>
  );
};
