import { FC } from 'react';
import { Divider } from '../bits/dividers';
import { useSidebar } from '../hooks/useSidebar';
import { HamburgerMenuToggleV2 } from './hamburgerMenuToggle';
import { useLocalizer } from '../localization';
import { NavItem } from './navItem';
import { NavLink } from './types';
import { useInternalUserBanner } from '../hooks/useInternalUserBanner';
import { FEATURE_NAME } from '../hooks/useFeatures';
import { useUser } from '../hooks/useUser';
import { FeatureGuard } from '../guards/featureGuard';
import { MessagesNavItem } from './messagesNavItem';
import { OffersNavItem } from './offersNavItem';

export const Sidebar: FC = () => {
  const loc = useLocalizer();

  const { visible, open, endTransition, transitioning, toggleSidebar } = useSidebar();
  const { user, userIsLoggedIn } = useUser();
  const loggedIn = userIsLoggedIn(user);
  const { sidebarPositionTop, maxSidebarHeightClass } = useInternalUserBanner();

  const style = (open ? 'min-w-70 ' : 'min-w-24 ') + 'relative transition-width duration-700 hidden lg:block';

  const welcomeMessage = user?.first_name ? loc.Nav.WelcomeUser(`${user?.first_name}`) : loc.Nav.Welcome;

  const readyForFullDisplay = open && !transitioning;

  const sidebarContent = () => {
    return (
      <>
        <div
          className={`flex w-full items-center bg-smokey-gray-50 px-4 py-4 text-moov-blue-500 lg:py-2 ${
            readyForFullDisplay ? 'justify-between' : 'justify-center'
          }`}
        >
          <div>{readyForFullDisplay && welcomeMessage}</div>

          {userIsLoggedIn(user) && visible && (
            <div>
              <HamburgerMenuToggleV2 onClick={toggleSidebar} isOpen={open} />
            </div>
          )}
        </div>

        <div className={`flex flex-col py-4 text-corp-gray-500 ${readyForFullDisplay ? 'px-4' : 'px-2 text-center'} `}>
          <NavItem link={NavLink.DASHBOARD} />
          <MessagesNavItem />
          <OffersNavItem />
          <Divider colorVariant="gray" className={`my-4 ${readyForFullDisplay ? '' : 'mx-2'}`} />
          <div className="flex flex-col gap-y-2">
            <NavItem link={NavLink.BUY} />
            <NavItem link={NavLink.REQUESTS} />
            <NavItem link={NavLink.TOOLS_IN_REVIEW} />
            <NavItem link={NavLink.SUGGESTED_TOOLS} />
            <NavItem link={NavLink.ORDERS} />
          </div>
          <Divider colorVariant="gray" className={`my-4 ${readyForFullDisplay ? '' : 'mx-2'}`} />
          <div className="flex flex-col gap-y-2">
            <NavItem link={NavLink.SELL} />
            <FeatureGuard featureName={FEATURE_NAME.LISTINGS}>
              <NavItem link={NavLink.LISTINGS} />
            </FeatureGuard>
            <FeatureGuard featureName={FEATURE_NAME.EMS}>
              <NavItem link={NavLink.EQUIPMENT} />
            </FeatureGuard>
            <NavItem link={NavLink.SOLD} />
          </div>
        </div>
      </>
    );
  };

  return visible && loggedIn ? (
    <div
      className={`border-r-2 border-gray-200 bg-white font-inter lg:z-sidebar ${style}`}
      onTransitionEnd={() => {
        endTransition();
      }}
      data-testid={'sidebar'}
    >
      {/* container to make the icons float down */}
      <div
        className={`transition-width sticky flex flex-col overflow-y-auto duration-700 ${sidebarPositionTop} ${maxSidebarHeightClass} ${
          open ? 'w-70' : 'hide-scrollbar w-24 items-center'
        }`}
      >
        {sidebarContent()}
      </div>
    </div>
  ) : (
    <></>
  );
};
