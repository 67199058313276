import { useEffect, useRef } from 'react';
import { useResizeObserver } from '@/src/hooks/useResizeObserver';
import { useAppDispatch } from '@/src/store';
import { setOffsetHeight } from './slice';

export const useAppBarResize = () => {
  const appBarRef = useRef<HTMLElement>(null);
  const { setObserver } = useResizeObserver();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!appBarRef.current) return;
    setObserver(appBarRef, () => {
      // The > 0 condition is for when the window is resized
      // and goes from mobile <-> desktop. We don't want to set it to 0
      // when either are removed from the DOM.
      if (appBarRef.current && appBarRef.current.offsetHeight > 0) {
        dispatch(setOffsetHeight(appBarRef.current.offsetHeight));
      }
    });
  }, [appBarRef.current]);

  return { appBarRef };
};
