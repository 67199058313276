import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HeaderState {
  offsetHeight: number;
}

export const initialState: HeaderState = {
  offsetHeight: 0
};

export const slice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setOffsetHeight: (state, action: PayloadAction<number>) => {
      state.offsetHeight = action.payload;
    }
  }
});

export const { setOffsetHeight } = slice.actions;

export default slice.reducer;
