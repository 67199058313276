import { useState } from 'react';
import { InputLabel, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import * as yup from 'yup';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { useLocalizer } from '@/src/localization';
import { GlobalModalTypes, closeModal, openModal } from '@/src/state/globalModal/slice';
import { ERROR_MESSAGES } from '@/src/constants';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { useFormValidation } from '@/src/hooks/useFormValidation';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { getContactFormSchema } from '@/src/validationSchemas/lead';
import { ContactForm, ContactFormValues } from '../../../forms/contactForm';
import { getModalState } from '@/src/state/globalModal/selectors';
import { isContactFormSubmitDisabled } from '../../../forms/contactForm/helpers';
import { colors, spacing } from '@/src/design/designConstants';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { CheckboxByte } from '@/src/design/bytes/checkbox';
import { openError } from '@/src/state/banner/slice';
import { captureException } from '@sentry/nextjs';
import { LabeledCurrencyInput } from '@/src/design/bytes/labeledCurrencyInput';
import { ListingForms, submitListingForm } from '@/src/state/forms/thunks';
import { isDTOLeadDefined } from '@/src/providers/leads/types';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { ExistingUserRegisterAccount } from '@/src/state/tracking/fullstory/customEvents/register';

export enum ModalSteps {
  CONTACT_INFO = 1,
  OFFER = 2
}

type MakeOfferPossibleSteps = ModalSteps.CONTACT_INFO | ModalSteps.OFFER;

export interface AnonMakeOfferFormValues extends ContactFormValues {
  is_offer: 1;
  offer_amount: string;
  destination: string;
  required_by: Date | null;
  insurance: boolean;
  logistics: boolean;
  appraisal: boolean;
  installation: boolean;
  listing_id: number | null;
  model_id: number | null;
}

/**
 * @deprecated
 */
export const AnonMakeOfferModal = () => {
  const dispatch = useAppDispatch();
  const loc = useLocalizer();
  const { openErrorAlert } = useGlobalAlertBanner();
  const {
    modalProps: { makeOffer }
  } = useAppSelector(getModalState);
  const [currentStep, setCurrentStep] = useState<MakeOfferPossibleSteps>(ModalSteps.CONTACT_INFO);
  const { fullStoryEvent } = useFullStory();

  const initialValues: AnonMakeOfferFormValues = {
    email: '',
    first_name: '',
    last_name: '',
    company: '',
    phone: '',
    contact_preference: 8,
    contact_handle: '',
    is_offer: 1,
    offer_amount: '',
    destination: '',
    required_by: null,
    insurance: false,
    logistics: false,
    appraisal: false,
    installation: false,
    listing_id: null,
    model_id: null
  };
  const formValidationHook = useFormValidation(
    initialValues,
    getContactFormSchema(loc).shape({
      is_offer: yup.number(),
      offer_amount: yup.string(),
      destination: yup.string(),
      insurance: yup.boolean(),
      logistics: yup.boolean(),
      appraisal: yup.boolean(),
      installation: yup.boolean()
    })
  );

  if (!makeOffer?.listing) return null;

  const steps: Record<
    ModalSteps,
    {
      title: string;
      actionButtonDisabled: boolean;
      actionButtonContent: string;
      actionButtonSubmit: () => Promise<void>;
      actionButtonId?: string;
      dismissButtonId?: string;
    }
  > = {
    [ModalSteps.CONTACT_INFO]: {
      title: 'Contact Information',
      actionButtonDisabled: isContactFormSubmitDisabled(formValidationHook),
      actionButtonContent: loc.Common.Next,
      actionButtonSubmit: async () => {
        const formIsValid = await formValidationHook.validateForm();
        if (formIsValid) {
          setCurrentStep(ModalSteps.OFFER);
        } else {
          openErrorAlert(ERROR_MESSAGES.FORM_INVALID);
          setCurrentStep(ModalSteps.CONTACT_INFO);
        }
      },
      actionButtonId: 'listing-make-offer-anon-step-1-submit-button',
      dismissButtonId: 'listing-make-offer-anon-step-1-dismiss-button'
    },
    [ModalSteps.OFFER]: {
      title: loc.Listing.MakeOffer,
      actionButtonDisabled: false,
      actionButtonContent: loc.Forms.Submit,
      actionButtonSubmit: async () => {
        fullStoryEvent(CustomEvents.listing.formSubmit, { type: 'MakeOffer' });
        return dispatch(
          submitListingForm(
            {
              from_url: window.location.href,
              company: formValidationHook.formValues.company,
              first_name: formValidationHook.formValues.first_name,
              last_name: formValidationHook.formValues.last_name,
              email: formValidationHook.formValues.email,
              phone: formValidationHook.formValues.phone,
              contact_preference: formValidationHook.formValues.contact_preference,
              contact_handle: formValidationHook.formValues.contact_handle,
              is_offer: 1,
              offer_amount: formValidationHook.formValues.offer_amount,
              destination: formValidationHook.formValues.destination,
              required_by: formValidationHook.formValues.required_by,
              insurance: formValidationHook.formValues.insurance,
              logistics: formValidationHook.formValues.logistics,
              appraisal: formValidationHook.formValues.appraisal,
              installation: formValidationHook.formValues.installation,
              listing_id: makeOffer.listing.id,
              listing_key: makeOffer.listing.key,
              model_id: makeOffer.listing.model.id,
              form_name: ListingForms.MAKE_OFFER
            },
            makeOffer.listing.key
          )
        )
          .then((lead) => {
            if (isDTOLeadDefined(lead) && !lead.user_id) {
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.CREATE_ACCOUNT,
                  modalProps: {
                    createAccount: {
                      leadInfo: {
                        email: lead?.email,
                        key: lead?.key,
                        first_name: lead?.first_name,
                        last_name: lead?.last_name,
                        company: lead?.company,
                        phone: lead?.phone
                      }
                    }
                  }
                })
              );
            } else {
              const email = formValidationHook.formValues.email;
              fullStoryEvent(ExistingUserRegisterAccount.REGISTER_EXISTING_ACCOUNT_PROMPTED, { email });
              dispatch(
                openModal({
                  modalType: GlobalModalTypes.EXISTING_ACCOUNT_RESET_PASSWORD,
                  modalProps: { existingAccountResetPassword: { email } }
                })
              );
            }
          })
          .catch((e) => {
            dispatch(
              openError({
                error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
              })
            );
            captureException(e);
          });
      },
      actionButtonId: 'listing-make-offer-anon-step-2-submit-button',
      dismissButtonId: 'listing-make-offer-anon-step-2-dismiss-button'
    }
  };

  const step2 = (
    <>
      <Stack spacing={spacing['xl']}>
        <Stack mb={spacing['3xl']}>
          <Typography variant="body1" color={colors.smokeyGray[500]}>
            {/* TODO: Translations */}
            Provide an offer and timeline below, if any additional services are required we can get started on preparing a quote.
          </Typography>
        </Stack>
        <LabeledCurrencyInput
          inputProps={{ 'data-testid': 'make-offer-offer-amount' }}
          value={formValidationHook.formValues.offer_amount}
          onChange={(e) => formValidationHook.setFieldValue('offer_amount', e.target.value)}
          variant="outlined"
          label={loc.Modals.OfferAmount}
          required
        />
        <LabeledTextField
          inputProps={{ 'data-testid': 'make-offer-destination' }}
          value={formValidationHook.formValues.destination}
          onChange={(e) => formValidationHook.setFieldValue('destination', e.target.value)}
          variant="outlined"
          label="Destination Address"
          placeholder="Destination"
        />
        <Stack>
          {/* TODO: Translations */}
          <InputLabel>Required By</InputLabel>
          <DatePicker
            value={formValidationHook.formValues.required_by}
            onChange={(v) => formValidationHook.setFieldValue('required_by', v)}
          />
        </Stack>
        <InputLabel>Do you require any of the following services?</InputLabel>
        <CheckboxByte
          data-testid="make-offer-checkbox-insurance"
          checked={!!formValidationHook.formValues.insurance}
          onChange={(checked) => {
            formValidationHook.setFieldValue('insurance', checked);
          }}
          label="Insurance"
        />
        <CheckboxByte
          data-testid="make-offer-checkbox-logistics"
          checked={!!formValidationHook.formValues.logistics}
          onChange={(checked) => {
            formValidationHook.setFieldValue('logistics', checked);
          }}
          label="Logistics"
        />
        <CheckboxByte
          data-testid="make-offer-checkbox-appraisal"
          checked={!!formValidationHook.formValues.appraisal}
          onChange={(checked) => {
            formValidationHook.setFieldValue('appraisal', checked);
          }}
          label="Appraisal"
        />
        <CheckboxByte
          data-testid="make-offer-checkbox-installation"
          checked={!!formValidationHook.formValues.installation}
          onChange={(checked) => {
            formValidationHook.setFieldValue('installation', checked);
          }}
          label="Install Assistance"
        />
      </Stack>
    </>
  );

  return (
    <WorkflowModal
      modalOpen
      closeModal={() => dispatch(closeModal())}
      totalSteps={2}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      {...steps[currentStep]}
      actionButtonId={steps[currentStep].actionButtonId ?? 'modal-action-button'}
      dismissButtonId={steps[currentStep].dismissButtonId ?? 'modal-dismiss-button'}
    >
      <Stack minHeight={{ d: '600px' }} width={{ d: '600px' }}>
        {currentStep === ModalSteps.CONTACT_INFO && <ContactForm formValidationHook={formValidationHook} />}
        {currentStep === ModalSteps.OFFER && step2}
      </Stack>
    </WorkflowModal>
  );
};
