import { Stack, Typography } from '@mui/material';
import { FAIcon } from '../design/bits/fAIcon/fAIcon';
import { icons } from '../design/bits/fAIcon/icons';
import { FeatureGuard } from '../guards/featureGuard';
import { FEATURE_NAME } from '../hooks/useFeatures';
import routes from '../routes';
import { borderRadius, colors, spacing } from '../design/designConstants';
import { InternalLink } from '../bits/links';
import { useRouter } from 'next/router';
import { useSidebar } from '../hooks/useSidebar';

export const OffersNavItem = () => {
  const router = useRouter();
  const isSelected = router.pathname === routes.offers();
  const { open, transitioning } = useSidebar();
  const selectedStyling = isSelected
    ? { backgroundColor: colors.moovBlue['50'], color: colors.moovBlue['500'], '& .make-offer-link-typography': { fontWeight: 600 } }
    : {
        '&:hover': { backgroundColor: colors.moovBlue['50'] },
        '&:hover .make-offer-link-typography': { color: colors.magenta['500'] }
      };

  const openAndNotTransitioning = open && !transitioning;
  return (
    <FeatureGuard featureName={FEATURE_NAME.MAKE_OFFER}>
      <InternalLink href={routes.offers()}>
        <Stack
          direction={openAndNotTransitioning ? 'row' : 'column'}
          sx={{ alignItems: 'center', ...selectedStyling }}
          borderRadius={borderRadius.rounded}
          spacing={spacing.md}
          mt={spacing.lg}
          p={spacing.md}
          data-testid="sidebar-make-offer-link"
        >
          <FAIcon icon={icons.briefCaseFilled} scale={1.25} />
          <Typography className="make-offer-link-typography" fontSize={openAndNotTransitioning ? '14px' : '11px'} variant="p14">
            {/* TODO: translations */}
            OFFERS
          </Typography>
        </Stack>
      </InternalLink>
    </FeatureGuard>
  );
};
