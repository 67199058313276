import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { spacing } from '@/src/design/designConstants';
import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { SellerOfferGroup } from '../types';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { setExpandedSellerOfferId } from '../slice';
import { getExpandedSellerOfferId } from '../selectors';
import { closeModal, GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';
import { parseUSDCurrencyStr } from '@/src/utils';
import { captureException } from '@sentry/nextjs';
import { openError } from '@/src/state/banner/slice';
import { ERROR_MESSAGES } from '@/src/constants';
import { acceptOffer } from '../thunks';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { OfferCTATypes, typedOffersEvent } from '@/src/state/tracking/fullstory/customEvents/offers';

export const SellerCTAs: FC<{ sellerOfferGroup: SellerOfferGroup }> = ({ sellerOfferGroup }) => {
  const dispatch = useAppDispatch();
  const expandedSellerOfferId = useAppSelector(getExpandedSellerOfferId);
  const { fullStoryAndGTMEvent } = useFullStory();

  const { highest_offer } = sellerOfferGroup;
  return (
    <Stack spacing={spacing.md}>
      {highest_offer && (
        <Button
          onClick={() => {
            fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
              type: OfferCTATypes.OpenAcceptHighestOffer,
              offerKey: highest_offer.key,
              listingKey: sellerOfferGroup.listing.key
            });
            dispatch(
              openModal({
                modalType: GlobalModalTypes.CONFIRMATION,
                modalProps: {
                  confirmation: {
                    title: 'Are you sure you want to accept this offer?',
                    description: `You will be accepting an offer of ${parseUSDCurrencyStr(highest_offer.offer_amount)} USD for ${
                      sellerOfferGroup.listing.make_model
                    }.`,
                    confirmText: 'Yes',
                    cancelText: 'No',
                    onConfirm: async () => {
                      fullStoryAndGTMEvent(typedOffersEvent.offerCTAClick, {
                        type: OfferCTATypes.ConfirmAcceptHighestOffer,
                        offerKey: highest_offer.key,
                        listingKey: sellerOfferGroup.listing.key
                      });
                      return dispatch(acceptOffer(highest_offer));
                    },
                    onClose: () => {
                      dispatch(closeModal());
                    },
                    errorHandleConfirm: (err) => {
                      captureException(err);
                      dispatch(openError({ error: ERROR_MESSAGES.REFRESH }));
                    }
                  }
                }
              })
            );
          }}
          size="large"
          variant="contained"
          color="primary"
        >
          <Typography noWrap variant="button14AllCaps">
            ACCEPT HIGHEST OFFER
          </Typography>
        </Button>
      )}
      <Button
        onClick={() => {
          if (sellerOfferGroup.listing.key === expandedSellerOfferId) {
            dispatch(setExpandedSellerOfferId(''));
          } else {
            dispatch(setExpandedSellerOfferId(sellerOfferGroup.listing.key));
          }
        }}
        size="large"
        variant="outlined"
        color="info"
      >
        <Stack spacing={spacing.md} direction="row">
          <FAIcon icon={icons.plus} />
          <Typography noWrap variant="button14AllCaps">
            VIEW ALL OFFERS
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
};
