import { colors, spacing } from '@/src/design/designConstants';
import { Button, Divider, Drawer, Stack, Typography } from '@mui/material';
import { getAllMessageThreads, getCurrentThread, getMessagesLoading } from '../../selectors';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { MessageSummary } from '../../messageSummary';
import { fetchAllMessages } from '../../thunks';
import { useEffect } from 'react';
import { MoovLoader } from '@/src/bits/forms/loaders';
import { MessagesNullState } from '../../nullState';
import { useInterval } from '@/src/hooks/useInterval';
import { deselectMessage } from '../../slice';
import { getHeaderOffsetHeight } from '@/src/layout/header/selectors';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { MessagesChatMobile } from '../mobile/mobileMessageChat';
import routes from '@/src/routes';
import { SideDrawers, useSideDrawer } from '@/src/hooks/useSideDrawer';

export const MessagesSideDrawer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading, initialized } = useAppSelector(getMessagesLoading);
  const { setIntvl, clearIntvl } = useInterval();
  const allMessageThreads = useAppSelector(getAllMessageThreads);
  const { drawerOpen, toggleDrawer } = useSideDrawer(SideDrawers.DesktopMessaging);
  const headerOffsetHight = useAppSelector(getHeaderOffsetHeight);
  const currentThread = useAppSelector(getCurrentThread);

  useEffect(() => {
    if (!drawerOpen) {
      // Clear interval when drawer is closed and deselect message
      clearIntvl();
      dispatch(deselectMessage());
      return;
    }

    //Fetch initial messages
    dispatch(fetchAllMessages({ setLoadFlags: true }));

    //Poll for new messages 2 minutes
    const interval = 2 * 60000;
    setIntvl(() => {
      dispatch(fetchAllMessages({ setLoadFlags: false }));
    }, interval);
  }, [drawerOpen]);

  let content = (
    <Stack data-testid="messaging-sidedrawer-thread-list" overflow="scroll" bgcolor={colors.surface.white}>
      <Stack mb="88px" width="100%" divider={<Divider />} borderBottom={`2px solid ${colors.corpGray['100']}`}>
        {allMessageThreads.map((thread) => {
          return <MessageSummary key={`listing-message-${thread.key}`} messageThread={thread} />;
        })}
      </Stack>
      <Stack
        borderTop={`2px solid ${colors.corpGray['100']}`}
        position="fixed"
        bottom="0"
        width={'fill-available'}
        p={spacing.xl}
        bgcolor={colors.surface.white}
      >
        <Button sx={{ height: '56px' }} fullWidth color="info" variant="outlined" href={routes.messages()}>
          <Stack spacing={spacing.md} direction="row" alignItems={'center'}>
            {/* TODO: translations */}
            <Typography variant="button14AllCaps">VIEW ALL MESSAGES</Typography>
          </Stack>
        </Button>
      </Stack>
    </Stack>
  );

  if (loading && !initialized) {
    content = (
      <Stack variant="center" width="100%" height="100%">
        <MoovLoader isLoading />
      </Stack>
    );
  }

  if (initialized && !allMessageThreads.length) {
    content = <MessagesNullState />;
  }

  return (
    <>
      <Drawer variant={'persistent'} anchor="right" open={drawerOpen && !Boolean(currentThread.key)}>
        <Stack
          width="500px"
          pt={`${headerOffsetHight}px`}
          data-testid="messaging-center-thread-list"
          overflow="scroll"
          bgcolor={colors.surface.white}
        >
          <Stack
            borderBottom={`2px solid ${colors.corpGray['100']}`}
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            pl={spacing.xl}
          >
            {/* TODO: translations */}
            <Typography variant="subtitle3">Messages</Typography>

            <Button sx={{ p: spacing.xl }} color="secondary" variant="text" onClick={toggleDrawer}>
              <FAIcon icon={icons.xMark} />
            </Button>
          </Stack>
          {content}
        </Stack>
      </Drawer>
      <Drawer variant="persistent" anchor="right" open={drawerOpen && Boolean(currentThread.key)}>
        <Stack width="500px" pt={`${headerOffsetHight}px`} height="fill-available">
          <MessagesChatMobile />
        </Stack>
      </Drawer>
    </>
  );
};
