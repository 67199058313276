import React from 'react';
// This import is required to avoid type errors when theming mui-x components
// reference :https://mui.com/x/react-date-pickers/base-concepts/#typescript
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

import { createTheme, LinearProgress } from '@mui/material';
import { borderRadius, breakpoints, colors, fontFamily, fontSize, fontWeight, spacing } from './designConstants';
import './customVariantTypes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { InternalLink } from '../bits/links';
/**
 * These properties are separated out from the main theme object so that the
 * helper methods from the Theme obj can be accessed.
 *
 * Example: `baseTheme.breakpoints.up('d')`
 */

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      d: breakpoints.d
    }
  },
  palette: {
    primary: {
      main: colors.magenta[500]
    },
    secondary: {
      light: colors.moovBlue[200],
      main: colors.moovBlue[500]
    },
    success: {
      main: colors.notification.crayolaGreen[500]
    },
    error: {
      lightest: '#FFF5F7',
      lighter: '#FFF1F1',
      light: '#FD385D40',
      main: '#F7022F'
    },
    warning: {
      light: colors.orange[50],
      main: colors.orange[500],
      dark: colors.orange[950]
    },
    accent: {
      light: '#FDF2F9',
      main: '#F4C8E5'
    }
  }
});

export const theme = createTheme({
  ...baseTheme,
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          width: 'fit-content',
          whiteSpace: 'nowrap',
          fontSize: '10px'
        }
      }
    },
    MuiAccordion: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            //This :before styling is to remove a gray line above the accordion before its open
            ':before': {
              display: 'none'
            },
            borderRadius: '8px !important',
            border: `1px solid ${colors.smokeyGray[200]}`,
            boxShadow: 'none'
          }
        }
      ]
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          paddingLeft: spacing.md
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <KeyboardArrowDownIcon />
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.smokeyGray[400]
          },
          '&:not-disabled:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.moovBlue[500]
          },
          '& .MuiInputBase-root.Mui-focused:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.skyBlue[600]
          },
          '& .MuiInputBase-root.Mui-error:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            borderColors: colors.notification.hotRed[500]
          },
          backgroundColor: colors.surface.white
        }
      }
    },
    MuiButton: {
      defaultProps: {
        LinkComponent: InternalLink,
        disableRipple: true,
        variant: 'contained',
        disableElevation: true
      },

      styleOverrides: {
        root: {
          '.MuiLoadingButton-loadingIndicator': {
            color: colors.surface.white
          },
          minWidth: '0px',
          textTransform: 'none'
        },

        sizeSmall: {
          height: spacing['4xl'],
          padding: `${spacing.xs} ${spacing.lg}`
        },
        /**
         * TODO: ENG-67 - Port over pre-styled button sizes from Figma. Will have to check
         * the site to see if changing styling breaks any UI since the default size is
         * medium
         */
        // sizeMedium: {
        //   height: spacing['5xl'],
        //   padding: `${spacing.md} ${spacing.xl}`
        // },
        // sizeLarge: {
        //   height: '56px',
        //   width: '288px',
        //   padding: `${spacing.xl} ${spacing.xl}`
        // },

        outlinedSizeSmall: {
          borderWidth: '1px',
          ':hover': {
            borderWidth: '1px'
          },
          ':focus': {
            borderWidth: '1px'
          },
          ':disabled': {
            borderWidth: '1px'
          }
        },

        outlinedPrimary: {
          borderWidth: '2px',
          color: colors.surface.white,
          borderColor: colors.magenta[500],
          ':hover': {
            borderWidth: '2px',
            backgroundColor: colors.magenta[600],
            borderColor: colors.magenta[600],
            color: colors.surface.white
          },
          ':focus': {
            borderWidth: '2px',
            backgroundColor: colors.magenta[700],
            borderColor: colors.magenta[700],
            color: colors.surface.white
          },
          ':disabled': {
            borderWidth: '2px',
            backgroundColor: colors.corpGray[200],
            borderColor: colors.corpGray[200],
            color: colors.corpGray[400]
          }
        },
        containedPrimary: {
          borderWidth: '2px',
          color: colors.surface.white,
          backgroundColor: colors.magenta[500],
          borderColor: colors.magenta[500],
          ':hover': {
            borderWidth: '2px',
            backgroundColor: colors.magenta[600],
            borderColor: colors.magenta[600],
            color: colors.surface.white
          },
          ':focus': {
            borderWidth: '2px',
            backgroundColor: colors.magenta[700],
            borderColor: colors.magenta[700],
            color: colors.surface.white
          },
          ':disabled': {
            borderWidth: '2px',
            backgroundColor: colors.corpGray[200],
            borderColor: colors.corpGray[200],
            color: colors.corpGray[400]
          }
        },
        outlinedSecondary: {
          borderWidth: '2px',
          color: colors.surface.white,
          borderColor: colors.moovBlue[500],
          ':hover': {
            borderWidth: '2px',
            backgroundColor: colors.moovBlue[600],
            borderColor: colors.moovBlue[600],
            color: colors.surface.white
          },
          ':focus': {
            borderWidth: '2px',
            backgroundColor: colors.moovBlue[800],
            borderColor: colors.moovBlue[800],
            color: colors.surface.white
          },
          ':disabled': {
            borderWidth: '2px',
            backgroundColor: colors.corpGray[200],
            borderColor: colors.corpGray[200],
            color: colors.corpGray[400]
          }
        },
        containedSecondary: {
          borderWidth: '2px',
          color: colors.surface.white,
          backgroundColor: colors.moovBlue[500],
          borderColor: colors.moovBlue[500],
          ':hover': {
            borderWidth: '2px',
            backgroundColor: colors.moovBlue[600],
            borderColor: colors.moovBlue[600],
            color: colors.surface.white
          },
          ':focus': {
            borderWidth: '2px',
            backgroundColor: colors.moovBlue[800],
            borderColor: colors.moovBlue[800],
            color: colors.surface.white
          },
          ':disabled': {
            borderWidth: '2px',
            backgroundColor: colors.corpGray[200],
            borderColor: colors.corpGray[200],
            color: colors.corpGray[400]
          }
        },
        outlinedSuccess: {
          borderWidth: '2px',
          color: colors.surface.white,
          borderColor: colors.crayolaGreen[500],
          ':hover': {
            borderWidth: '2px',
            backgroundColor: colors.crayolaGreen[600],
            borderColor: colors.crayolaGreen[600],
            color: colors.surface.white
          },
          ':focus': {
            borderWidth: '2px',
            backgroundColor: colors.crayolaGreen[700],
            borderColor: colors.crayolaGreen[700],
            color: colors.surface.white
          },
          ':disabled': {
            borderWidth: '2px',
            backgroundColor: colors.corpGray[200],
            borderColor: colors.corpGray[200],
            color: colors.corpGray[400]
          }
        },
        containedSuccess: {
          borderWidth: '2px',
          color: colors.surface.white,
          backgroundColor: colors.crayolaGreen[500],
          borderColor: colors.crayolaGreen[500],
          ':hover': {
            borderWidth: '2px',
            backgroundColor: colors.crayolaGreen[600],
            borderColor: colors.crayolaGreen[600],
            color: colors.surface.white
          },
          ':focus': {
            borderWidth: '2px',
            backgroundColor: colors.crayolaGreen[700],
            borderColor: colors.crayolaGreen[700],
            color: colors.surface.white
          },
          ':disabled': {
            borderWidth: '2px',
            backgroundColor: colors.corpGray[200],
            borderColor: colors.corpGray[200],
            color: colors.corpGray[400]
          }
        },
        outlinedInfo: {
          borderWidth: '2px',
          backgroundColor: colors.surface.white,
          color: colors.moovBlue[500],
          borderColor: colors.corpGray[200],
          ':hover': {
            borderWidth: '2px',
            backgroundColor: colors.surface.white,
            color: colors.moovBlue[500],
            borderColor: colors.corpGray[400]
          },
          ':focus': {
            borderWidth: '2px',
            backgroundColor: colors.surface.white,
            borderColor: colors.moovBlue[800],
            color: colors.moovBlue[800]
          },
          ':disabled': {
            borderWidth: '2px',
            borderColor: colors.corpGray[200],
            backgroundColor: colors.corpGray[200],
            color: colors.corpGray[400]
          }
        },
        containedInfo: {
          borderWidth: '2px',
          backgroundColor: colors.surface.white,
          color: colors.moovBlue[500],
          borderColor: colors.corpGray[200],
          ':hover': {
            borderWidth: '2px',
            backgroundColor: colors.surface.white,
            color: colors.moovBlue[500],
            borderColor: colors.corpGray[400]
          },
          ':focus': {
            borderWidth: '2px',
            backgroundColor: colors.surface.white,
            borderColor: colors.moovBlue[800],
            color: colors.moovBlue[800]
          },
          ':disabled': {
            borderWidth: '2px',
            borderColor: colors.corpGray[200],
            backgroundColor: colors.corpGray[200],
            color: colors.corpGray[400]
          }
        },
        textPrimary: {
          color: colors.magenta[500],
          textTransform: 'none',
          fontFamily: fontFamily.inter,
          fontSize: '1rem',
          fontWeight: 700
        },
        textSecondary: {
          color: colors.moovBlue[500],
          textTransform: 'none'
        },
        textSuccess: {
          color: colors.crayolaGreen[500],
          textTransform: 'none'
        },
        textInfo: {
          color: colors.moovBlue[500],
          textTransform: 'none'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: InternalLink
      }
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${colors.smokeyGray[200]}`,
            background: colors.surface.white,
            color: 'inherit'
          }
        }
      ]
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.smokeyGray[300],
          '&.Mui-checked': { color: colors.skyBlue[500] },
          paddingTop: spacing.sm,
          paddingBottom: spacing.sm
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        colorInfo: {
          backgroundColor: colors.brand['50'],
          color: colors.brand['500'],
          border: `1px solid ${colors.brand[200]}`
        },
        colorSuccess: {
          backgroundColor: colors.notification.crayolaGreen[50],
          color: colors.notification.crayolaGreen[500]
        },
        colorError: {
          backgroundColor: colors.notification.hotRed[50],
          color: colors.notification.hotRed[500]
        },
        colorWarning: {
          backgroundColor: colors.orange[50],
          color: colors.orange[500]
        },
        colorPrimary: {
          backgroundColor: colors.magenta[50],
          color: colors.magenta[500]
        },
        outlined: {
          fontFamily: fontFamily.inter,
          fontSize: fontSize['12spx'],
          fontWeight: 600,
          padding: '16px 7px',
          lineHeight: 'normal',
          letterSpacing: '0.4px'
        },
        filledSecondary: {
          background: colors.surface.magentaGradient,
          fontFamily: fontFamily.inter,
          fontWeight: fontWeight[400],
          color: colors.surface.white,
          letterSpacing: '0.4px',
          fontSize: fontSize['9spx'],
          textTransform: 'uppercase'
        }
      }
    },
    MuiDataGrid: {
      defaultProps: {
        disableDensitySelector: true,
        hideFooter: true,
        slots: {
          loadingOverlay: LinearProgress
        },
        slotProps: {
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true
          }
        }
      },
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: 0,
          color: colors.moovBlue[500],

          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.moovBlue[50],
            borderBottomColor: colors.moovBlue[200],
            color: colors.corpGray[500],
            fontSize: '12px',
            textTransform: 'uppercase'
          },
          '& .MuiDataGrid-columnSeparator': {
            color: colors.moovBlue[200]
          },
          '& .MuiFormControl-root': {
            paddingBottom: 0
          },
          '& .MuiInputBase-input': {
            marginLeft: 0
          },
          '& .MuiDataGrid-row:not(:hover)': {
            '.MuiButton-outlinedInfo': {
              borderColor: 'transparent',

              '.MuiButton-endIcon': {
                svg: {
                  color: 'transparent !important'
                }
              }
            }
          }
        },
        cell: {
          borderBottom: `1px solid ${colors.corpGray['200']}`,
          borderRight: `1px solid ${colors.corpGray['200']}`,
          color: colors.moovBlue[500],
          fontFamily: fontFamily.inter,
          fontSize: fontSize['14spx'],
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',

          ':focus': {
            outline: 'none'
          },
          ':focus-within': {
            outline: 'none'
          }
        },
        row: {
          '&.MuiDataGrid-row:hover': {
            backgroundColor: colors.moovBlue[50]
          },
          '&.Mui-selected': {
            backgroundColor: colors.magenta[50],
            fontWeight: 'bold'
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.magenta[50],
            fontWeight: 'bold'
          }
        },
        toolbarContainer: {
          backgroundColor: colors.moovBlue[100],
          borderBottom: `1px solid ${colors.moovBlue[200]}`
        }
      }
    },
    MuiDatePicker: {
      defaultProps: {
        slots: {
          openPickerIcon: () => <FAIcon icon={icons.calendarDays} />
        }
      }
    },
    MuiDivider: {
      variants: [
        {
          props: {
            variant: 'form'
          },
          style: { borderWidth: '1px', opacity: 1, borderColor: colors.smokeyGray['100'] }
        },
        {
          props: {
            variant: 'filters'
          },
          style: {
            borderBottomWidth: '1px',
            borderColor: colors.moovBlue[200]
          }
        }
      ],
      styleOverrides: {
        /**
         * To style this component in the simplest and most succinct way,
         * target borderBottomWidth and borderColor as these are the properties
         * used in MUI's default styling of Divider.
         */
        root: {
          borderRightWidth: 'initial',
          borderBottomWidth: '2px',
          borderColor: colors.smokeyGray[100]
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          color: colors.moovBlue[500],
          boxShadow: '-20px 0 20px rgba(0,0,0,.1)',
          clipPath: `inset(0px 0px 0px -100px);`,

          '&::-webkit-scrollbar ': {
            display: 'none'
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: colors.moovBlue[500],
          fontFamily: fontFamily.inter,
          fontSize: fontSize['14spx'],
          fontWeight: 400,
          lineHeight: 'normal'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.moovBlue[500]
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.moovBlue[500],
          fontFamily: fontFamily.inter,
          fontSize: '14px',
          fontWeight: 700,
          letterSpacing: '0.075px',
          marginBottom: '6px',
          ':empty': {
            marginBottom: 0
          }
        }
      }
    },
    MuiIconButton: {
      defaultProps: { LinkComponent: InternalLink }
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          height: spacing['5xl']
        },
        root: {
          fontFamily: fontFamily.inter,
          color: colors.moovBlue[500],
          backgroundColor: colors.surface.white,

          input: {
            '::placeholder': {
              fontFamily: fontFamily.inter,
              fontSize: 'inherit',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              letterSpacing: '0.15px',
              color: colors.smokeyGray[400],
              opacity: 1
            }
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.skyBlue[600]
          },
          '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.notification.hotRed[500]
          }
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: InternalLink
      },
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent: InternalLink,
        disableRipple: true
      }
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: { noWrap: true }
      },
      styleOverrides: {
        root: {
          color: colors.moovBlue[500],
          marginTop: 0,
          marginBottom: 0
        }
      }
    },
    MuiLoadingButton: {
      defaultProps: {
        LinkComponent: InternalLink,
        disableRipple: true,
        variant: 'contained',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          minWidth: '0px'
        }
      }
    },
    MuiMenuItem: {
      defaultProps: {
        LinkComponent: InternalLink,
        disableRipple: true
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.surface.white,
          fontFamily: fontFamily.inter,
          padding: '0.45rem 1rem',
          ['&:hover, &.Mui-selected:hover']: {
            backgroundColor: colors.moovBlue['50']
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: colors.smokeyGray[400],
          ':hover': {
            borderColor: colors.moovBlue[500]
          }
        }
      }
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${colors.smokeyGray[200]}`,
            background: colors.surface.white,
            color: 'inherit'
          }
        }
      ]
    },
    MuiSelect: {
      defaultProps: { IconComponent: KeyboardArrowDownIcon },
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.skyBlue[600]
          },
          minWidth: 0
        },
        icon: {
          color: colors.moovBlue[500]
        },
        outlined: {
          backgroundColor: colors.surface.white
        }
      }
    },
    MuiStack: {
      variants: [
        {
          props: { variant: 'center' },
          style: {
            justifyContent: 'center',
            alignItems: 'center'
          }
        }
      ]
    },
    MuiStepButton: {
      defaultProps: {
        LinkComponent: InternalLink
      }
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            color: colors.surface.white
          },
          '&.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+ .MuiSwitch-track': {
            backgroundColor: colors.moovBlue[500],
            opacity: '1'
          }
        }
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: InternalLink
      },
      styleOverrides: {
        root: {
          color: colors.smokeyGray['500'],
          fontWeight: '600',
          fontFamily: fontFamily.inter,
          fontSize: '0.875rem',
          lineHeight: '1.125rem',
          letterSpacing: '0.025rem',
          textTransform: 'uppercase',
          '&.Mui-selected': {
            color: colors.moovBlue['500']
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'auto',
            color: colors.corpGray['300']
          }
        }
      }
    },
    MuiTableSortLabel: {
      defaultProps: { LinkComponent: InternalLink }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: '0'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.skyBlue[500]
        }
      }
    },
    MuiTextField: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: {
        root: {
          sx: {
            '& .MuiInputBase-input': {
              color: colors.moovBlue[500],
              fontFamily: fontFamily.inter,
              letterSpacing: '0.009375em' // TODO: consts?
            },
            '& ::placeholder': {
              color: colors.smokeyGray[400],
              opacity: 1
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.smokeyGray[400]
            },
            '& :not-disabled:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.moovBlue[500]
            },
            '.MuiInputBase-root.Mui-focused:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.skyBlue[600]
            },
            '.MuiInputBase-root.Mui-error:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
              borderColors: colors.notification.hotRed[500]
            },
            '.MuiFormHelperText-root': {
              marginTop: '0px',
              marginLeft: '0px'
            },
            '.MuiChip-root .MuiChip-deleteIcon': {
              color: colors.smokeyGray[400]
            }
          }
        }
      }
    },
    MuiToggleButton: {
      defaultProps: { LinkComponent: InternalLink }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '40px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',

            [baseTheme.breakpoints.up('d')]: {
              fontSize: '72px'
            }
          }
        },
        {
          props: {
            variant: 'h2'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '36px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '150%',

            [baseTheme.breakpoints.up('d')]: {
              fontSize: '64px',
              lineHeight: 'normal'
            }
          }
        },
        {
          props: {
            variant: 'h3'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%',

            [baseTheme.breakpoints.up('d')]: {
              fontSize: '48px'
            }
          }
        },
        {
          props: {
            variant: 'h3Bold'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 800,
            lineHeight: '150%',

            [baseTheme.breakpoints.up('d')]: {
              fontSize: '48px'
            }
          }
        },
        {
          props: {
            variant: 'h4'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '150%',

            [baseTheme.breakpoints.up('d')]: {
              fontSize: '36px'
            }
          }
        },
        {
          props: {
            variant: 'h5'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '150%',

            [baseTheme.breakpoints.up('d')]: {
              fontSize: '24px'
            }
          }
        },
        {
          props: {
            variant: 'h6'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '150%',

            [baseTheme.breakpoints.up('d')]: {
              fontSize: '20px'
            }
          }
        },
        {
          props: {
            variant: 'subtitle1'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%'
          }
        },
        {
          props: {
            variant: 'subtitle2'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%'
          }
        },
        {
          props: {
            variant: 'subtitle3'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal'
          }
        },
        {
          props: {
            variant: 'subheadline'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          }
        },
        {
          props: {
            variant: 'body1'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%'
          }
        },
        {
          props: {
            variant: 'body2'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          }
        },
        {
          props: {
            variant: 'p14'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          }
        },
        {
          props: {
            variant: 'p12'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          }
        },
        {
          props: {
            variant: 'p10'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          }
        },
        {
          props: {
            variant: 'overline'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '1.25px',
            textTransform: 'uppercase'
          }
        },
        {
          props: {
            variant: 'button16'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.8px',
            textTransform: 'uppercase'
          }
        },
        {
          props: {
            variant: 'button14AllCaps'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            textTransform: 'uppercase'
          }
        },
        {
          props: {
            variant: 'button14TitleCase'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            textTransform: 'capitalize'
          }
        },
        {
          props: {
            variant: 'button12AllCaps'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            textTransform: 'uppercase'
          }
        },
        {
          props: {
            variant: 'button12TitleCase'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            textTransform: 'capitalize'
          }
        },
        {
          props: {
            variant: 'caption'
          },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            fontFeatureSettings: "'clig' off, 'liga' off;"
          }
        },
        {
          props: { variant: 'form' },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: '1rem',
            fontWeight: 700,
            color: colors.moovBlue[500]
          }
        },
        {
          props: { variant: 'tableColumnHeader' },
          style: {
            fontFamily: fontFamily.inter,
            fontSize: fontSize['12spx'],
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0.36px',
            textTransform: 'uppercase'
          }
        }
      ]
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top'
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.surface.white,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          color: colors.moovBlue[500],
          padding: spacing['3xl'],
          borderRadius: borderRadius.roundedLg
        },
        arrow: {
          ':before': {
            backgroundColor: colors.surface.white
          }
        }
      }
    }
  }
});
