import { UseFormReturn } from 'react-hook-form';
import { MakeOfferFormValues } from './makeOffer';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLogisticCountries } from '@/src/state/logistics/selectors';
import { useEffect } from 'react';

import { borderRadius, colors, spacing } from '@/src/design/designConstants';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { CheckboxByte } from '@/src/design/bytes/checkbox';
import { useAppDispatch } from '@/src/store';
import { GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';
import dayjs from 'dayjs';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const MakeOfferStepReview: React.FC<{
  hookForm: UseFormReturn<MakeOfferFormValues, any, undefined>;
}> = ({ hookForm }) => {
  const countries = useSelector(getLogisticCountries);
  const dispatch = useAppDispatch();
  hookForm.watch([
    'offer_amount',
    'destination_address.country',
    'required_by',
    'offer_amount',
    'terms_and_conditions',
    'offer_subject_to'
  ]);

  useEffect(() => {
    //$currentStep changing in the validation schema does not re-trigger
    //validations, we need to retrigger terms_and_conditions validation manaully
    //here on mount and unmount(this is due to going back to the previous step)
    hookForm.trigger('terms_and_conditions');
    return () => {
      hookForm.trigger('terms_and_conditions');
    };
  }, []);

  return (
    // TODO: translations
    <Stack spacing={spacing.lg}>
      <Grid alignItems="center" container rowSpacing={spacing['2xl']}>
        <Grid item d={3.5} xs={6}>
          <strong>Offer Amount:</strong>
        </Grid>
        <Grid item d={8.5} xs={6}>
          <Box sx={{ pl: 1 }}>{formatter.format(hookForm.getValues('offer_amount'))} USD</Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item d={3.5} xs={6}>
          <strong>Destination Country:</strong>
        </Grid>
        <Grid item d={8.5} xs={6}>
          <Box sx={{ pl: 1 }}>{countries.find((country) => country.id === hookForm.getValues('destination_address.country'))?.label}</Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item d={3.5} xs={6}>
          <strong>Date Required By:</strong>
        </Grid>
        <Grid item d={8.5} xs={6}>
          <Box sx={{ pl: 1 }}>
            {hookForm.getValues('required_by') ? dayjs(hookForm.getValues('required_by')).format('MM/DD/YYYY') : 'N/A'}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {Boolean(hookForm.getValues('offer_subject_to_note')) && (
          <>
            <Grid item d={3.5} xs={6}>
              <strong>Offer Subject To:</strong>
            </Grid>
            <Grid item d={8.5} xs={6}>
              <Box sx={{ pl: 1 }}>{hookForm.getValues('offer_subject_to_note')}</Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
      </Grid>
      {Boolean(hookForm.getValues('offer_note')) && (
        <Stack
          borderRadius={borderRadius.rounded}
          width="fit-content"
          bgcolor={colors.yellow['50']}
          direction="row"
          py={spacing.md}
          px={spacing.lg}
          spacing={spacing.sm}
        >
          <FAIcon icon={icons.noteSticky} scale={1} />
          <Typography variant="p14">
            <strong>Offer Note:</strong>
            {'  '}
            {hookForm.getValues('offer_note')}
          </Typography>
        </Stack>
      )}

      {!Boolean(hookForm.getValues('offer_subject_to')) && (
        <CheckboxByte
          sx={{ alignItems: 'flex-start' }}
          checked={hookForm.getValues('terms_and_conditions')}
          onChange={(checked) => {
            hookForm.setValue('terms_and_conditions', checked);
            hookForm.trigger('terms_and_conditions');
          }}
          label={
            <>
              <Stack spacing={spacing.lg}>
                {/* TODO: translations */}
                <Typography variant="p14">
                  I acknowledge that by clicking submit you are agreeing to buy this item if your offer is accepted and you will be required
                  to pay the amount in full within 5 days. The seller has 48hrs to respond. Failure to comply may result in account
                  suspension.
                </Typography>
                <Typography variant="p14">
                  <i>If more time is required to pay, we suggest you</i>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    variant="p12"
                    color={colors.skyBlue['600']}
                    fontWeight="600"
                    onClick={() =>
                      dispatch(
                        openModal({
                          modalType: GlobalModalTypes.CONTACT_SALES,
                          modalProps: {}
                        })
                      )
                    }
                  >
                    &nbsp; Contact Moov Sales
                  </Typography>
                </Typography>
              </Stack>
            </>
          }
        />
      )}
    </Stack>
  );
};
