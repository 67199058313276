import { OffersSellersTab } from './sellers';
import { OffersBuyersTab } from './buyers';
import { Divider, Stack, Tab, Tabs } from '@mui/material';
import { PageWrapper } from '../layout/contentWrappers';
import { useRouter } from 'next/router';
import routes from '../routes';
import { BuyerOfferFilters } from './buyers/filters';
import { colors } from '../design/designConstants';
import { SellerOfferFilters } from './sellers/filters';

export enum OffersTabs {
  Selling = 'Selling',
  Buying = 'Buying'
}

const getCurrentTab = (tab: OffersTabs) => {
  switch (tab) {
    case OffersTabs.Buying:
      return OffersTabs.Buying;
    case OffersTabs.Selling:
      return OffersTabs.Selling;
    default:
      return OffersTabs.Selling;
  }
};

const OffersMain = () => {
  const router = useRouter();
  const currentTab = getCurrentTab(router.query?.tab as OffersTabs);

  return (
    <PageWrapper
      className="w-full overflow-hidden overflow-y-scroll bg-corp-gray-50 text-moov-blue-500"
      childrenClassName="p-0"
      title={'Offers'}
    >
      <Stack>
        <Stack direction="row" justifyContent={'space-between'} alignItems="center" bgcolor={colors.surface.white}>
          <Tabs
            value={currentTab}
            onChange={(_, tab) => {
              switch (tab) {
                case OffersTabs.Selling:
                  router.push(routes.offersSelling());
                  break;
                case OffersTabs.Buying:
                  router.push(routes.offersBuying());
                  break;
              }
            }}
          >
            {/* TODO: translations */}
            <Tab label={OffersTabs.Selling} value={OffersTabs.Selling} />
            <Tab label={OffersTabs.Buying} value={OffersTabs.Buying} />
          </Tabs>

          {currentTab === OffersTabs.Buying && <BuyerOfferFilters />}
          {currentTab === OffersTabs.Selling && <SellerOfferFilters />}
        </Stack>
        <Divider />
        {currentTab === OffersTabs.Selling && <OffersSellersTab />}
        {currentTab === OffersTabs.Buying && <OffersBuyersTab />}
      </Stack>
    </PageWrapper>
  );
};

export default OffersMain;
