import { useState } from 'react';
import { SetModal, SwapInModal } from '../../entities/common';

export const useModal = (setModal: SetModal) => {
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
    setModal(null);
  };

  const swapInModal: SwapInModal = (modal: JSX.Element) => {
    setIsOpen(false);
    setModal(modal);
  };

  return {
    isOpen,
    onClose,
    swapInModal
  };
};
