import { Formik } from 'formik';
import dynamic from 'next/dynamic';
import * as yup from 'yup';

import ModalPopup from './modalPopup';
import { GTM_EVENT_TYPES } from '../../integrations/googleTagManager/constants';
import { useLocalizer } from '../../localization';
import routes from '../../routes';
import FormikPasswordField from '../forms/formikFields/formikPasswordField';
import FormikTextField from '../forms/formikFields/formikTextField';
import { FormikFormWrapper } from '../forms/formikFields/formikWrappers';
import { InternalLink } from '../links';
import { CreateUserSession } from '../../entities/user/types';
import { SetModal } from '../../entities/common';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { useModal } from '../../hooks/useModal';
import { useUser } from '../../hooks/useUser';
import { MoovButton } from '../moovButton';
import { useGTM } from '../../hooks/useGTM';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { startRegistration } from '@/src/state/router/slice';
import { useRouter } from 'next/router';

const ResetPasswordModal = dynamic(() => import('./resetPasswordModal'), { ssr: false });

/**
 *
 */
const LoginModal = ({ setModal }: { setModal: SetModal }) => {
  const loc = useLocalizer();
  const { isOpen, onClose, swapInModal } = useModal(setModal);
  const { loginUser } = useUser();
  const { openErrorAlert } = useGlobalAlertBanner();
  const { trackGTMEvent } = useGTM();
  const { fullStoryEvent } = useFullStory();
  const dispatch = useDispatch();
  const { asPath } = useRouter();

  const handleSubmit = async (values: CreateUserSession) => {
    try {
      fullStoryEvent(CustomEvents.login.loginFinish);

      await loginUser(values);
      onClose(); // must be called before refreshUser() to prevent memory leak caused by swapping logged-out home navbar with logged-in home navbar
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data.message === 'Invalid User/Password') {
          openErrorAlert(loc.ErrorMessage.InvalidUserPassword);
        } else {
          openErrorAlert(loc.ErrorMessage.SomethingWentWrongRefreshPage);
        }
      }
    }
  };

  const handleRegister = () => {
    fullStoryEvent(CustomEvents.register.loginModalClick);
    trackGTMEvent({
      event: GTM_EVENT_TYPES.register.loginModalClick
    });

    dispatch(startRegistration(asPath));

    onClose();
  };

  const handleResetPasswordClick = () => {
    swapInModal(<ResetPasswordModal setModal={setModal} />);
  };

  const initialValues: CreateUserSession = {
    email: '',
    password: ''
  };

  const validationSchema = yup.object({
    email: yup.string().email('Please enter a valid email').required('Email is required'),
    password: yup.string().required('Password is required')
  });

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose} className="max-w-sm-modal">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, errors, submitCount }) => (
          <FormikFormWrapper isSubmitting={isSubmitting} errors={errors} submitCount={submitCount}>
            <div className="flex flex-col items-center px-8 pb-16 pt-4">
              <div className="mb-8 text-20spx font-bold capitalize">{loc.Common.WelcomeBack}</div>

              <div className="mb-4 flex w-full flex-col gap-2 text-left">
                <FormikTextField type="email" name="email" placeholder={loc.Forms.Email} required />
                <FormikPasswordField name="password" placeholder={loc.Forms.Password} required />
              </div>

              <div className="mb-8 flex justify-center">
                <MoovButton variant="secondary" className="w-56 px-3 py-2.5" type="submit" id="login-modal-finish-click">
                  {loc.Common.SignIn}
                </MoovButton>
              </div>

              <button type="button" onClick={handleResetPasswordClick} className="cursor-pointer bg-transparent font-bold hover:underline">
                {loc.Modals.ForgotPassword}
              </button>

              <div>
                {loc.Modals.NoAccount} {` `}
                {/* TODO: do we need a custom event for this? */}
                <InternalLink onClick={handleRegister} href={routes.register()} id="login-modal-register-link" className="hover:underline">
                  {loc.Modals.RegisterHere}
                </InternalLink>
              </div>
            </div>
          </FormikFormWrapper>
        )}
      </Formik>
    </ModalPopup>
  );
};

export default LoginModal;
