import { useEffect } from 'react';
import { fetchBuyerOffers } from '../thunks';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { getBuyerOffers, getFilteredBuyerOffers, getOffersLoading } from '../selectors';
import { Card, CardContent, CardHeader, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { MoovLoader } from '@/src/bits/forms/loaders';
import { borderRadius, colors, spacing } from '@/src/design/designConstants';
import Image from 'next/image';
import { parseUSDCurrencyStr } from '@/src/utils';
import dayjs from 'dayjs';
import { OfferStatusChip } from '../statusChip';
import { ReadableOfferStatus } from '../types';
import { BuyerOfferCTAs } from './ctas';
import { IMAGE_URLS } from '@/src/constants/imageUrls';
import { reset } from '../slice';
import { getLogisticCountries } from '@/src/state/logistics/selectors';
import { NullFilterState } from '../nullFilterState';

export const OffersBuyersTab = () => {
  const dispatch = useAppDispatch();
  const { initialized, loading } = useAppSelector(getOffersLoading);
  const filteredBuyerOffers = useAppSelector(getFilteredBuyerOffers);

  const allBuyerOffers = useAppSelector(getBuyerOffers);
  const countryOptions = useAppSelector(getLogisticCountries);
  useEffect(() => {
    dispatch(fetchBuyerOffers());

    return () => {
      //Reset on unmount so state doesnt affect seller tab
      dispatch(reset());
    };
  }, []);

  if (!initialized && loading) {
    return (
      <Stack variant="center" width="100%" height="100%">
        <MoovLoader isLoading />
      </Stack>
    );
  }

  if (initialized && !allBuyerOffers.length) {
    return (
      <Stack variant="center" alignItems={'center'} width="100%" height="100%">
        <Stack width="100%" p={spacing['2xl']} spacing={spacing.xl}>
          <Stack variant="center" spacing={spacing.md} textAlign={'center'}>
            <Typography color={colors.corpGray['500']} variant="subtitle3">
              No Current Offers
            </Typography>
            <Typography color={colors.corpGray['500']} variant="p14">
              No offers yet. Be the first to place an offer and secure the equipment you need!
            </Typography>
          </Stack>
          <Stack width="100%" variant="center" py={spacing.md} px={spacing.sm}>
            <img src={IMAGE_URLS.OFFER_NULL_STATE} style={{ height: '170px', maxWidth: '500px' }} alt="order card illustration" />
          </Stack>
        </Stack>
      </Stack>
    );
  }

  if (initialized && !filteredBuyerOffers.length) {
    return <NullFilterState />;
  }

  return (
    <Stack margin="auto" px={spacing['10xl']} py={spacing['3xl']} spacing={spacing['3xl']} height="100%">
      {filteredBuyerOffers.map((offer) => {
        return (
          <Card variant="outlined" sx={{ px: spacing['3xl'], py: spacing['xl'] }} key={`buyer-offer-${offer.key}`}>
            <Stack direction="row" alignItems={'center'} spacing={spacing['3xl']}>
              <Stack>
                <Image
                  alt={`listing-image-${offer.listing?.make_model}`}
                  src={offer.listingLargePhotoUrl}
                  quality={100}
                  objectFit="cover"
                  height={200}
                  width={200}
                  style={{ borderRadius: borderRadius.rounded }}
                />
              </Stack>
              <Stack spacing={spacing['3xl']} width="100%">
                <CardHeader
                  sx={{ p: 0 }}
                  title={
                    <Stack direction="row" justifyContent={'space-between'}>
                      <Typography variant="h6">{offer.listing.make_model}</Typography>
                      <OfferStatusChip offer={offer.offer} />
                    </Stack>
                  }
                />
                <CardContent sx={{ p: 0 }}>
                  <Stack direction="row" justifyContent={'space-between'} spacing={spacing['4xl']}>
                    <Stack width="75%">
                      <Grid container alignItems="center" rowGap={spacing.xs} spacing={spacing.sm}>
                        <Grid xs={5}>
                          <Typography variant="p14">
                            <strong>Offer Amount:</strong>
                          </Typography>
                        </Grid>
                        <Grid xs={7}>
                          <Typography
                            variant="p14"
                            color={
                              offer.offer.readable_status === ReadableOfferStatus.ACCEPTED
                                ? colors.crayolaGreen['500']
                                : offer.offer.readable_status === ReadableOfferStatus.DECLINED
                                ? colors.notification.hotRed['500']
                                : ReadableOfferStatus.EXPIRED === offer.offer.readable_status
                                ? colors.orange['500']
                                : colors.crayolaGreen['500']
                            }
                          >
                            <strong>{parseUSDCurrencyStr(offer.offer.offer_amount)} USD</strong>
                          </Typography>
                        </Grid>

                        <Grid xs={12}>
                          <Divider />
                        </Grid>

                        <Grid xs={5}>
                          <Typography variant="p14">
                            <strong>Date Required By:</strong>
                          </Typography>
                        </Grid>
                        <Grid xs={7}>
                          <Typography variant="p14">
                            {dayjs(offer.offer.required_by).isValid() ? dayjs(offer.offer.required_by).format('MM/DD/YYYY') : 'N/A'}
                          </Typography>
                        </Grid>

                        <Grid xs={12}>
                          <Divider />
                        </Grid>

                        <Grid xs={5}>
                          <Typography variant="p14">
                            <strong>Destination Country:</strong>
                          </Typography>
                        </Grid>
                        <Grid xs={7}>
                          <Typography variant="p14">{offer.countryText(countryOptions)}</Typography>
                        </Grid>

                        <Grid xs={12}>
                          <Divider />
                        </Grid>

                        <Grid xs={5}>
                          <Typography variant="p14">
                            <strong>Offer Expiration:</strong>
                          </Typography>
                        </Grid>
                        <Grid xs={7}>
                          <Typography
                            variant="p14"
                            color={
                              offer.offer.readable_status === ReadableOfferStatus.ACCEPTED
                                ? colors.crayolaGreen['500']
                                : offer.offer.readable_status === ReadableOfferStatus.DECLINED
                                ? colors.notification.hotRed['500']
                                : ReadableOfferStatus.EXPIRED === offer.offer.readable_status
                                ? colors.orange['500']
                                : colors.magenta['500']
                            }
                          >
                            <strong>{offer.expireDaysText}</strong>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                    <BuyerOfferCTAs offer={offer} />
                  </Stack>
                </CardContent>
              </Stack>
            </Stack>
          </Card>
        );
      })}
    </Stack>
  );
};
