export interface ImageData {
  url: string;
  originalDimensions: {
    width: number;
    height: number;
  };
}

export const IMAGE_URLS = {
  // LOGOS
  MOOV_LOGO_DARK_BLUE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moov-dark-blue.svg',
  MOOV_LOGO_ICON: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moov-icon.svg',
  MOOV_LOGO_LOADER: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/assets-12-2019/loading_anim.gif',
  MICROCHIP_LOGO: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/Microchip_Technology_dark.png',
  AMKOR_LOGO: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/amkor-logo-dark.png',
  LG_LOGO: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/lg-logo-dark.png',
  SEMI_LOGO: 'https://www.semi.org/themes/custom/semi/logo.svg',
  NXP_LOGO: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/NXP-logo-dark.png',
  SAMSUNG_LOGO: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/samsung-logo.svg',

  // PHOTOS OF MOOVERS
  MEISSNER_AND_HANNA_IN_GOLF_CART: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/meissner-and-hanna-in-golf-cart-sm.jpg',
  LIT_UP_MOOV_SIGN_WITH_BALLOONS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/lit-up-moov-sign-with-balloons-sm.jpg',
  GLORIOUS_STACHE_THUMBS_UP_IN_STADIUM:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/glorious-stache-thumbs-up-in-stadium-sm.jpg',
  SHAY: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/shay.jpeg',
  MOOVERS_COLLAGE_GOLF: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moovers-collage-golf.svg',
  MOOVERS_COLLAGE_BUSINESS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moovers-collage-business.svg',
  MOOVERS_COLLAGE_POOL: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moovers-collage-pool.svg',

  // CAREER PAGE ASSETS
  FUN_ENVIRONMENT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/moov_lunch.jpg',
  MOOV_WITH_CITY_MAYOR: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/moov_with_city_mayor.jpg',
  NEW_OFFICE_BUILDING: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/new_office_building.jpg',
  DECORATIVE_SHAPES_1: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/decorative-shapes-1.svg',
  DECORATIVE_SHAPES_2: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/decorative-shapes-2.svg',
  DECORATIVE_SHAPES_3: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/decorative-shapes-3.svg',
  HEADSHOT_OF_ANNIE_TENG: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/headshot-annie-teng-gray-bg.svg',
  HEADSHOT_OF_MOE_QUINONEZ: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/headshot-moe-quinonez-gray-bg.svg',
  HEADSHOT_OF_JACK_MORAN: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/headshot-jack-moran-gray-bg.svg',
  GREAT_OAKS_LOGO: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/great-oaks-logo.png',

  SLIDESHOW_HANNAH_MATH_PRODIGY:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-hannah-math-prodigy.svg',
  SLIDESHOW_SAMMY_AT_SEMICON:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-sammy-at-semicon.jpg',
  SLIDESHOW_MAX_TALKING_BUSINESS:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-max-talking-business.svg',
  SLIDESHOW_MEGHAN_IN_KITCHEN:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-meghan-in-kitchen.svg',
  SLIDESHOW_MOOV_HQ_FAR_VIEW:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-moov-HQ-far-view.svg',
  SLIDESHOW_MOOVERS_AT_LUNCH_1:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-moovers-at-lunch-1.svg',
  SLIDESHOW_MOOVERS_AT_LUNCH_2:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-moovers-at-lunch-2.svg',
  SLIDESHOW_MOOVERS_AT_SEMICON_3:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-moovers-at-semicon-3.svg',
  SLIDESHOW_MOOVERS_AT_SEMICON_5:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-moov-at-semicon-5.png',
  SLIDESHOW_MOOVERS_ESCAPE_ROOM:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-moovers-escape-room.svg',
  SLIDESHOW_MOOVERS_FOOTBALL:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-moovers-football.svg',
  SLIDESHOW_MOOVERS_IN_CONSTRUCTION_VESTS:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-moovers-in-construction-vests.svg',
  SLIDESHOW_NICK_AND_LAUREN_BUNNY_SUITS:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-nick-and-lauren-bunny-suits.svg',
  SLIDESHOW_PRODUCT_MASTERS:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-product-masters.svg',
  SLIDESHOW_STEVE_CHAMPAGNE_TOWER:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-steve-champagne-tower.svg',
  SLIDESHOW_STEVE_CHI_AND_MAX_THE_DARK_KNIGHT:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-steve-chi-and-max-the-dark-knight.svg',
  SLIDESHOW_HALLOWEEN_UNICORN:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-halloween-unicorn.jpg',
  SLIDESHOW_HALLOWEEN: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-halloween.JPG',
  SLIDESHOW_KBBQ: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-kbbq.png',
  SLIDESHOW_STEVE_MAX_MEISSNER:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/career-page/careers-slideshow-image-steve-max-meissner.png',

  // TEAM PAGE ASSETS
  MOOV_TEMPE_ARIZONA_HEADQUARTERS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moov-tempe-arizona-headquarters-sm.png',
  MAXAM_YEUNG_HEADSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/maxam-yeung-headshot-sm.jpg',
  STEVEN_ZHOU_HEADSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/steven-zhou-headshot-sm.jpg',
  HEATHER_BIGGS_HEADSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/heather-biggs-headshot-sm.jpg',
  RAJIV_CHEGU_HEADSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/rajiv-chegu-headshot-sm.jpg',
  NICK_GRIFFITH_HEADSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/nick-griffith-headshot-sm.jpg',
  RAYMOND_MAHON_HEADSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/raymond-mahon-headshot-sm.jpg',
  JAMES_QIU_HEADSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/james-qiu-headshot-sm.jpg',
  LAUREN_STELLWAG_HEADSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/lauren-stellwag-headshot-sm.jpg',
  ANDREW_WOLSTAN_HEADSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/andrew-wolstan-headshot-sm.jpg',
  TEMPE_ARIZONA: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/tempe-arizona.png',
  AUSTIN_TEXAS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/austin-texis.png',
  SAN_FRANCISCO_CALIFORNIA: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/san-francisco-california.png',
  HIGH_FIVE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/high-five.svg',

  // TRENDS PAGE ASSETS
  MARKET_TRENDS_POSTER: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/market-trends-2022-poster.png',
  MARKET_TRENDS_PDF_ICON: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/market-trends-2022-pdf-icon.png',
  MARKET_TRENDS_BAR_GRAPH: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/market-trends-bar-graph.png',
  MARKET_TRENDS_BAR_GRAPH_MOBILE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/market-trends-bar-graph-mobile.png',
  MARKET_TRENDS_PIE_CHART: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/market-trends-pie-chart.png',
  MARKET_TRENDS_PIE_CHART_MOBILE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/market-trends-pie-chart-mobile.png',

  // REMARKETING PAGE ASSETS
  REMARKETING_GET_IN_TOUCH: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/remarketing-get-in-touch.png',
  REMARKETING_GET_IN_TOUCH_MOBILE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/remarketing-get-in-touch-mobile.png',
  REMARKETING_BREADCRUMBS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/remarketing-breadcrumbs.svg',
  REMARKETING_BREADCRUMBS_MOBILE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/remarketing-breadcrumbs-mobile.svg',
  REMARKETING_COMPARISON_GRAPH: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/remarketing-comparison-graph.svg',
  REMARKETING_COMPARISON_GRAPH_MOBILE:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/remarketing-comparison-graph-mobile.svg',
  REMARKETING_DASH_SNAPSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/remarketing-dashboard-snapshot.svg',
  REMARKETING_DASH_SNAPSHOT_MOBILE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/remarketing-dashboard-snapshot-mobile.svg',

  // SETTLEMENT PAGE ASSETS
  MAP_AVAILABLE_SOON_GRAPHIC: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/map-available-soon-graphic.png',

  // GENERAL ICONS
  ICON_MAIL_CIRCLE_FILLED: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-mail-circle-filled.svg',
  ICON_PHONE_CIRCLE_FILLED: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-phone-circle-filled.svg',
  ICON_RECEIPT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-receipt.svg',
  BUNNY_SUIT_404: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/bunny-suit-404.svg',
  ICON_CHART_LINE_UP: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-chart-line-up.svg',
  ICON_INVENTORY: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-inventory.svg',
  ICON_PAYMENT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-payment.svg',
  ICON_PAYMENTS_SETTLEMENTS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-payments-settlement.svg',
  ICON_TEAM: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-team.svg',
  ICON_TRUCK: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-truck.svg',

  // NULL STATE ASSETS
  NULL_STATE_ORDERS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/orders-null-graphic.svg',
  NULL_STATE_SOLD: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/sold-null-graphic.svg',
  NULL_STATE_SUGGESTED_ITEM: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/suggested-items-null-graphic.svg',
  NULL_STATE_YOUR_PICKS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/your-picks-null-graphic.svg',
  NULL_STATE_LISTINGS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/listings-null-graphic.svg',
  NULL_STATE_TOOL_REQUESTS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/tool-requests-null-graphic.svg',
  NULL_STATE_PHOTO_SMALL: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/photo-coming-soon-small.png',
  NULL_STATE_PHOTO_LARGE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/photo-coming-soon-large.png',

  // FILE ICONS
  ICON_FILE_CSV: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-file-CSV.svg',
  ICON_FILE_DOC: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-file-DOC.svg',
  ICON_FILE_DOCX: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-file-DOCX.svg',
  ICON_FILE_JPG: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-file-JPG.svg',
  ICON_FILE_PDF: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-file-PDF.svg',
  ICON_FILE_PNG: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-file-PNG.svg',
  ICON_FILE_GENERIC: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/icon-file-generic.svg',

  // SVG ICONS
  TRUCK_BLUE_SVG: {
    url: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/truck-blue.svg',
    originalDimensions: { width: 28, height: 23 }
  },
  CHECKMARK_SHIELD_BLUE_SVG: {
    url: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/checkmark-shield-blue.svg',
    originalDimensions: { width: 28, height: 31 }
  },
  DOTTED_CLOCKWISE_ARROW_BLUE_SVG: {
    url: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/dotted-clockwise-arrow-blue.svg',
    originalDimensions: { width: 28, height: 34 }
  },
  BUMPY_CIRCLE_CHECKMARK_BLUE_SVG: {
    url: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/bumpy-circle-checkmark-blue.svg',
    originalDimensions: { width: 28, height: 28 }
  },
  SERVICE_TILE_TRUCK_GRAY_SVG: {
    url: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/service-tile-truck-gray.svg',
    originalDimensions: { width: 161, height: 94 }
  },
  SERVICE_TILE_CHECKMARK_SHIELD_GRAY_SVG: {
    url: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/service-tile-checkmark-shield-gray.svg',
    originalDimensions: { width: 157, height: 118 }
  },
  SERVICE_TILE_DOTTED_CLOCKWISE_ARROW_GRAY_SVG: {
    url: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/service-tile-clockwise-arrows-gray.svg',
    originalDimensions: { width: 127, height: 118 }
  },
  SERVICE_TILE_BUMPY_CIRCLE_CHECKMARK_GRAY_SVG: {
    url: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/service-tile-bumpy-circle-checkmark-gray.svg',
    originalDimensions: { width: 157, height: 110 }
  },
  OUTLINE_GREEN_SHIELD_WITH_CHECKMARK_SVG: {
    url: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/outline-green-shield-with-checkmark.svg',
    originalDimensions: { width: 28, height: 30 }
  },

  // MISC
  LASER_CUTTER: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/laser-cutter-with-purple-glow.png',
  FILE_ICON: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/file-icon.png',
  BLUE_TRACKING_DOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/blue-tracker-dot.png', // 20 x 20
  EXAMPLE_LISTING_CARD_1: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/example-listing-card-1.png',
  EXAMPLE_LISTING_CARD_2: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/example-listing-card-2.png',
  EXAMPLE_LISTING_CARD_3: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/example-listing-card-3.png',
  HIRING_BANNER: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/hiring-banner.png',
  INTRO_VIDEO_THUMBNAIL: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moov-intro-video.png',
  LISTING_PLACEHOLDER_IMAGE_SM: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/noPhotos.png',
  LISTING_PLACEHOLDER_IMAGE_LG: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/defaultPhoto.png',
  BIG_SQUARE_WITH_SMALLER_SQUARES: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/big-square-with-three-smaller-squares.png',
  TWO_PAIRS_OF_SQUARES: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/two-pairs-of-squares-in-opposite-corners.png',
  MAGENTA_CHECKMARK_CIRCLE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/magenta-checkmark-circle.svg',
  DARK_BLUE_HEXAGON_X: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/dark-blue-hexagon-x.svg',
  DARK_BLUE_PLUS_SIGN_CIRCLE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/dark-blue-plus-sign-circle.svg',
  DEFAULT_PROFILE_PICTURE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/default-profile-picture.png',
  MOOV_DIFFERENCE_BG: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moov-difference-bg.png',
  EXCLUSIVES_PAGE_SCREENSHOT: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/exclusives-page-screenshot.png',

  // HOME FEATURES SLIDER
  FEATURES_DETAILED_TRACKING: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/features-detailed-tracking.png',
  FEATURES_MANAGES_LISTINGS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/features-manages-listings.png',
  FEATURES_TOOL_REQUESTS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/features-tool-requests.png',

  // STOCK PHOTOS
  PEOPLE_GATHERED_AROUND_LAPTOP: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/four-people-gathered-around-laptop.png',
  HIGH_VIEW_OF_OFFICE_WITH_LIGHTS:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/high-view-of-indoor-office-with-hanging-lights.png',
  PEOPLE_WITH_ARMS_AROUND_SHOULDERS:
    'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/six-people-with-arms-around-shoulders.png',
  PEOPLE_IN_CLEANROOM_SUITS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/three-people-in-cleanroom-suits.png',
  PEOPLE_SITTING_CLOSE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/four-people-sitting-close.png',
  WIDE_VIEW_OF_OFFICE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/wide-view-of-indoor-office.png',
  PEOPLE_WITH_LAPTOPS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/close-view-of-people-with-laptops.png',
  HUSTLE_CUP_ON_TABLE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/hustle-cup-on-table.png',
  CELEBRATING_BIRTHDAY: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/four-people-celebrating-a-birthday.png',
  PEOPLE_IN_PRESENTATION: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/four-people-in-presentation.png',
  OFFICE_GAL_PALS: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/office-gal-pals.png',
  GIRL_WITH_LAPTOP_LAUGHING: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/girl-with-laptop-laughing-at-joke.png',
  PEOPLE_PLAYING_PING_PONG: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/two-people-playing-ping-pong.png',
  PUNCH_TODAY_IN_THE_FACE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/punch-today-in-the-face.png',
  PEOPLE_AT_TABLE_SMILING: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/two-people-at-a-table-smiling.png',
  SALESMAN: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/salesman-portrait.png',

  // MESSAGING FEATURE ASSETS
  ORDER_CARD_ILLUSTRATION: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/order-card-illustration.png',

  // OFFERS FEATURE ASSETS
  OFFER_NULL_STATE: 'https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/offers-null-state.png',

  // FUNCTIONS
  getDefaultMoovImageURL: (id: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8, size: 's' | 'm' | 'l') =>
    `https://d2pkkbyngq3xpw.cloudfront.net/moov_media/default_moov_image_0${id}_${size}.png`
};
