import { getModalState } from '@/src/state/globalModal/selectors';
import { closeModal } from '@/src/state/globalModal/slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { Stack } from '@mui/material';
import { useGlobalAlertBanner } from '@/src/hooks/useGlobalAlertBanner';
import { useEffect, useState } from 'react';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { spacing } from '@/src/design/designConstants';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { createMessageForListing } from '@/src/messages/thunks';
import { MessagePolicyTypography } from '@/src/messages/messagePolicyTypography';
import { typedMessageEvent } from '@/src/state/tracking/fullstory/customEvents/messages';
import { useRouter } from 'next/router';
import { ListingMessageSellerCTAQueryParam } from '@/src/marketplace/listingV2/types';

export const MessageSellerModal = () => {
  const dispatch = useAppDispatch();
  const {
    modalProps: { messageSeller }
  } = useAppSelector(getModalState);
  const [message, setMessage] = useState('');
  const { openSuccessMessage } = useGlobalAlertBanner();
  const { fullStoryAndGTMEvent } = useFullStory();
  const router = useRouter();

  useEffect(() => {
    return () => {
      if (router.query?.cta === ListingMessageSellerCTAQueryParam.MESSAGE_SELLER) {
        // Remove cta from query on unmount
        const query = { ...router.query };
        delete query.cta;
        router.replace({ query });
      }
    };
  }, []);

  if (!messageSeller) return null;

  const sendMessage = async () => {
    fullStoryAndGTMEvent(typedMessageEvent.messagingSendMessage, { message, listingKey: messageSeller.listingKey });
    return dispatch(createMessageForListing({ body: message, listingKey: messageSeller.listingKey })).then(() => {
      openSuccessMessage('Message sent!');
      onClose();
    });
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <WorkflowModal
      // TODO: translations Send a Message
      title={'Send a Message'}
      modalOpen={true}
      currentStep={0}
      totalSteps={1}
      closeModal={() => {
        fullStoryAndGTMEvent(typedMessageEvent.messagingCancelMessage, { listingKey: messageSeller.listingKey });
        dispatch(closeModal());
      }}
      setCurrentStep={() => {}}
      actionButtonContent={'SEND MESSAGE'}
      actionButtonSubmit={sendMessage}
      dismissButtonContent={'CANCEL'}
      dismissButtonSubmit={async () => {
        fullStoryAndGTMEvent(typedMessageEvent.messagingCancelMessage, { listingKey: messageSeller.listingKey });
        onClose();
      }}
      actionButtonDisabled={message.length === 0}
      displayBackButton={false}
    >
      <Stack width={{ d: '600px' }} spacing={spacing.sm} data-testid="message-seller-modal-content">
        <LabeledTextField
          value={message}
          label={'Message'}
          onChange={(e) => setMessage(e.currentTarget.value)}
          multiline
          minRows={3}
          maxRows={6}
        />
        <MessagePolicyTypography />
      </Stack>
    </WorkflowModal>
  );
};
