import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { closeModal } from '@/src/state/globalModal/slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { getModalState } from '@/src/state/globalModal/selectors';
import { colors, spacing } from '@/src/design/designConstants';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { Address } from '@/src/common/lib';
import joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { MakeOfferStepReview } from './makeOfferStepReview';
import { useRouter } from 'next/router';
import routes from '@/src/routes';
import { SearchFlowType } from '@/src/marketplace/search/types';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { MakeOfferStepOffer } from './makeOfferStepOffer';
import { createOrUpdateOffer, MakeOfferPayload } from '@/src/offers/thunks';
import { captureException } from '@sentry/nextjs';
import { openError } from '@/src/state/banner/slice';
import { ERROR_MESSAGES } from '@/src/constants';
import { typedOffersEvent } from '@/src/state/tracking/fullstory/customEvents/offers';
import { BuyerOfferSerializable, ReadableOfferStatus } from '@/src/offers/types';
import { ListingMessageSellerCTAQueryParam } from '@/src/marketplace/listingV2/types';

export enum MakeOfferSteps {
  OFFER = 1,
  REVIEW = 2,
  DECLINED = 3,
  ACCEPTED = 4
}

// TODO: translations
const getTitle = (step: MakeOfferSteps, offer?: BuyerOfferSerializable) => {
  switch (step) {
    case MakeOfferSteps.OFFER:
      if (typeof offer?.offer.readable_status === 'string') {
        if ([ReadableOfferStatus.DECLINED, ReadableOfferStatus.PENDING].includes(offer.offer.readable_status)) return 'Increase Offer';
        if (ReadableOfferStatus.EXPIRED === offer.offer.readable_status) return 'Submit Offer';
      }
      return 'Make Offer';
    case MakeOfferSteps.REVIEW:
      return 'Review Offer';
    case MakeOfferSteps.DECLINED:
      return (
        <Stack spacing={spacing.sm} variant="center" direction="row">
          <FAIcon icon={icons.circleExclamation} scale={1.5} color={colors.notification.hotRed['500']} />
          <Typography variant="h6">Offer Declined</Typography>
        </Stack>
      );
    case MakeOfferSteps.ACCEPTED:
      return (
        <Stack spacing={spacing.sm} variant="center" direction="row">
          <FAIcon icon={icons.circleCheck} scale={1.5} color={colors.crayolaGreen['500']} />
          <Typography variant="h6">Offer Sent!</Typography>
        </Stack>
      );
    default:
      return 'Make Offer';
  }
};

// TODO: translations
const getActionButtonTitle = (step: MakeOfferSteps) => {
  switch (step) {
    case MakeOfferSteps.OFFER:
      return 'Continue';
    case MakeOfferSteps.REVIEW:
      return 'Submit';
    case MakeOfferSteps.DECLINED:
      return 'Make New Offer';
    case MakeOfferSteps.ACCEPTED:
      return 'Done';
    default:
      return 'Continue';
  }
};

const getDismissbuttonTitle = (step: MakeOfferSteps) => {
  return step === MakeOfferSteps.DECLINED ? 'Browse Similar Listings' : undefined;
};

export interface MakeOfferFormValues extends MakeOfferPayload {
  terms_and_conditions: boolean;
}

const joiSchema = joi.object({
  offer_amount: joi.number().min(1).required(),
  destination_address: joi.object({
    country: joi.string().min(1).required()
  }),
  offer_note: joi.string().allow(''),
  offer_subject_to: joi.boolean().allow(false, true),
  offer_subject_to_note: joi.string().when('offer_subject_to', {
    is: true,
    then: joi.string().min(1).required(),
    otherwise: joi.string().allow('')
  }),
  required_by: joi.string().allow(''),
  terms_and_conditions: joi.boolean().when(joi.ref('$currentStep'), {
    is: MakeOfferSteps.REVIEW,
    then: joi.when('offer_subject_to', {
      is: false,
      then: joi.boolean().valid(true).required(),
      otherwise: joi.any()
    }),
    otherwise: joi.any()
  })
});

export const MakeOffer = () => {
  const dispatch = useAppDispatch();
  const {
    modalProps: { makeOffer }
  } = useAppSelector(getModalState);
  const [currentStep, setCurrentStep] = useState<MakeOfferSteps>(MakeOfferSteps.OFFER);
  const router = useRouter();
  const { fullStoryAndGTMEvent } = useFullStory();

  useEffect(() => {
    return () => {
      if (router.query?.cta === ListingMessageSellerCTAQueryParam.MAKE_OFFER) {
        const query = { ...router.query };
        delete query.cta;
        router.replace({ query });
      }
    };
  }, []);

  const defaultValues: MakeOfferFormValues = {
    offer_amount: 0,
    destination_address: new Address().toSerializable(),
    offer_note: '',
    offer_subject_to: false,
    offer_subject_to_note: '',
    required_by: '',
    terms_and_conditions: false,
    //If offer is passed in, overwrite default values
    ...(makeOffer?.offer && {
      offer_amount: makeOffer.offer.offer.offer_amount,
      destination_address: makeOffer.offer.offer.destination_address,
      required_by: makeOffer.offer.offer.required_by ?? '',
      offer_note: makeOffer.offer.offer.offer_note,
      offer_subject_to: Boolean(makeOffer.offer.offer.offer_subject_to),
      offer_subject_to_note: makeOffer.offer.offer.offer_subject_to_note
    })
  };

  const hookForm = useForm<MakeOfferFormValues>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(joiSchema),
    context: { currentStep }
  });

  if (!makeOffer?.listing) {
    return null;
  }

  return (
    <WorkflowModal
      modalOpen
      closeModal={() => dispatch(closeModal())}
      totalSteps={MakeOfferSteps.REVIEW}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      actionButtonSubmit={async () => {
        if (currentStep === MakeOfferSteps.OFFER) {
          setCurrentStep(MakeOfferSteps.REVIEW);
        } else if (currentStep === MakeOfferSteps.REVIEW) {
          fullStoryAndGTMEvent(CustomEvents.listing.formSubmit, { type: 'MakeOffer' });

          //Decline offers less than 100
          //Move validation to API when its smarter
          if (hookForm.getValues('offer_amount') < 100) {
            fullStoryAndGTMEvent(typedOffersEvent.offerSubmitDeclined, {
              listingKey: makeOffer.listing.key,
              offerAmount: hookForm.getValues('offer_amount')
            });
            setCurrentStep(MakeOfferSteps.DECLINED);
            return;
          }

          return dispatch(
            createOrUpdateOffer({ body: hookForm.getValues(), listingKey: makeOffer.listing.key, offerKey: makeOffer.offer?.key })
          )
            .then(() => {
              fullStoryAndGTMEvent(typedOffersEvent.offerSubmitAccepted, {
                listingKey: makeOffer.listing.key,
                offerAmount: hookForm.getValues('offer_amount')
              });
              setCurrentStep(MakeOfferSteps.ACCEPTED);
            })
            .catch((e) => {
              captureException(e);
              dispatch(openError({ error: ERROR_MESSAGES.REFRESH }));
            });
        } else if (currentStep === MakeOfferSteps.DECLINED) {
          setCurrentStep(MakeOfferSteps.OFFER);
          hookForm.reset();
        } else if (currentStep === MakeOfferSteps.ACCEPTED) {
          dispatch(closeModal());
        }
      }}
      title={getTitle(currentStep, makeOffer.offer)}
      dismissButtonSubmit={
        currentStep === MakeOfferSteps.DECLINED
          ? async () => {
              dispatch(closeModal());
              router.push(routes.search({ search_flow_type: SearchFlowType.Model, model_id: [makeOffer.listing.model.id] }));
            }
          : undefined
      }
      dismissButtonContent={getDismissbuttonTitle(currentStep)}
      actionButtonContent={getActionButtonTitle(currentStep)}
      actionButtonDisabled={!hookForm.formState.isValid}
      displayBackButton={currentStep === MakeOfferSteps.REVIEW}
    >
      <Stack sx={{ width: { d: '600px' } }} data-testid="make-offer-modal-content">
        {currentStep === MakeOfferSteps.OFFER && <MakeOfferStepOffer hookForm={hookForm} />}
        {currentStep === MakeOfferSteps.REVIEW && <MakeOfferStepReview hookForm={hookForm} />}
        {currentStep === MakeOfferSteps.DECLINED && (
          <Stack variant="center" p={spacing['3xl']}>
            {/* TODO: translations */}
            <Typography variant="body1">Don&apos;t worry, you can still make a new offer.</Typography>
          </Stack>
        )}
        {currentStep === MakeOfferSteps.ACCEPTED && (
          <Stack variant="center" p={spacing['3xl']}>
            <Typography variant="body1">
              {/* TODO: translations */}
              Your offer has been shared with the seller, they will have 7 days to accept or reject your offer. We will notify you with any
              updates!
            </Typography>
          </Stack>
        )}
      </Stack>
    </WorkflowModal>
  );
};
