import { colors, spacing } from '@/src/design/designConstants';
import { FormControl, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { MakeOfferFormValues } from './makeOffer';
import { LabeledCurrencyInput } from '@/src/design/bytes/labeledCurrencyInput';
import { useLocalizer } from '@/src/localization';
import { RequiredLabel } from '@/src/design/bits/requiredLabel';
import { CountryAutocomplete } from '@/src/design/kilos/countrySelect';
import { getLogisticCountries } from '@/src/state/logistics/selectors';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';
import { CheckboxByte } from '@/src/design/bytes/checkbox';
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

export const MakeOfferStepOffer: React.FC<{ hookForm: UseFormReturn<MakeOfferFormValues, any, undefined> }> = ({ hookForm }) => {
  const loc = useLocalizer();
  const countryOptions = useAppSelector(getLogisticCountries);
  const dispatch = useAppDispatch();
  hookForm.watch(['offer_note', 'offer_subject_to', 'offer_subject_to_note', 'required_by']);

  return (
    <Stack spacing={spacing['3xl']}>
      <LabeledCurrencyInput
        {...hookForm.register('offer_amount')}
        inputProps={{ 'data-testid': 'make-offer-offer-amount' }}
        value={hookForm.getValues('offer_amount') ? hookForm.getValues('offer_amount').toString() : ''}
        onChange={(e) => {
          hookForm.setValue('offer_amount', Number(e.target.value));
          hookForm.trigger('offer_amount');
        }}
        variant="outlined"
        error={!!hookForm.formState.errors['offer_amount']}
        label={loc.Modals.OfferAmount}
        required
      />
      <Controller
        name="destination_address.country"
        control={hookForm.control}
        render={({ field }) => (
          <FormControl error={Boolean(hookForm.formState.errors['destination_address'])} fullWidth>
            <FormLabel error={Boolean(hookForm.formState.errors['destination_address'])}>
              {loc.Listing.Location}
              <RequiredLabel error={Boolean(hookForm.formState.errors['destination_address'])} />
            </FormLabel>
            <CountryAutocomplete
              // Ref undefined unsets the ref on field, we get a lot of console errors
              // w/o this and it doesn't break functionality
              {...{ ...field, ref: undefined }}
              placeholder="Choose Destination Country"
              error={Boolean(hookForm.formState.errors['destination_address'])}
              data-testid="make-offer-country-select"
              options={countryOptions}
              value={countryOptions.find((option) => option.id === hookForm.getValues('destination_address').country) || null}
              handleChange={(country) => {
                field.onChange(country?.id || '');
                hookForm.trigger('destination_address');
              }}
              size="medium"
            />
          </FormControl>
        )}
      />
      <FormControl fullWidth data-testid="make-offer-required-by">
        <FormLabel>Date Required By</FormLabel>
        <DatePicker<Dayjs>
          slotProps={{ textField: { size: 'medium', error: false }, field: { clearable: true } }}
          value={dayjs(hookForm.getValues('required_by'))}
          onChange={(value) => {
            hookForm.setValue('required_by', value?.isValid() ? value.toISOString() : '');
          }}
        />
      </FormControl>
      <LabeledTextField
        inputProps={{ 'data-testid': 'make-offer-destination' }}
        value={hookForm.getValues('offer_note')}
        onChange={(e) => {
          hookForm.setValue('offer_note', e.target.value);
        }}
        variant="outlined"
        label="Note"
        placeholder="Type a message here"
      />
      <Typography variant="p14">
        All offers are final upon seller approval, if you require more information please complete the <strong>subject to</strong> field
        below or&nbsp;
        <Typography
          sx={{ cursor: 'pointer' }}
          variant="p12"
          color={colors.skyBlue['600']}
          fontWeight="600"
          onClick={() =>
            dispatch(
              openModal({
                modalType: GlobalModalTypes.CONTACT_SALES,
                modalProps: {}
              })
            )
          }
        >
          Contact Moov Sales
        </Typography>
      </Typography>
      <Stack>
        <CheckboxByte
          label="Subject To"
          checked={hookForm.getValues('offer_subject_to')}
          onChange={(checked) => {
            hookForm.setValue('offer_subject_to', checked);
            if (!checked) {
              // Reset the offer_subject_to_note field if the checkbox is unchecked
              hookForm.resetField('offer_subject_to_note');
            }
            //Set the terms_and_conditions field to false and revalidate
            //This resets the field if they are going back and forth
            hookForm.setValue('terms_and_conditions', false);
            hookForm.trigger('terms_and_conditions');
          }}
        />
        {hookForm.getValues('offer_subject_to') && (
          <TextField
            {...hookForm.register('offer_subject_to_note')}
            variant="outlined"
            error={Boolean(hookForm.formState.errors['offer_subject_to_note'])}
            placeholder="Enter requirements such as Inspection"
          />
        )}
      </Stack>
    </Stack>
  );
};
