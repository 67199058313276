import { FC, ReactNode, useEffect } from 'react';
import { WorkflowButtons } from './workflowButtons';
import { WorkflowHeader } from './workflowHeader';
import Modal from '../modal';
import classNames from 'classnames';
import { unreachable } from '@/src/utils/typeSafe';
import { scrollElementToTop } from '@/src/utils/elementUtils';

export interface WorkflowModalProps<TCurrentStep extends number = number> {
  modalOpen: boolean;
  closeModal: () => void;
  backgroundColor?: 'white';
  currentStep: TCurrentStep;
  totalSteps: TCurrentStep;
  title: string | ReactNode;
  actionButtonSubmit: () => Promise<void>;
  dismissButtonSubmit?: () => Promise<void>;
  onBackButtonClick?: () => void;
  setCurrentStep: (step: TCurrentStep) => void;
  actionButtonId?: string; // TODO: make both action and dismiss ids required and collapse into actionbutton and dismissbutton objects
  dismissButtonId?: string;
  actionButtonContent: ReactNode;
  dismissButtonContent?: ReactNode;
  actionButtonDisabled?: boolean;
  displayProgressBar?: boolean;
  displayBackButton?: boolean;
  progressBarColor?: string;
  progressBackgroundColor?: string;
  contentClassName?: string;
}

const WorkflowModal = <TCurrentStep extends number = number>(props: WorkflowModalProps<TCurrentStep> & Parameters<FC>[0]) => {
  const { modalOpen, closeModal, children, backgroundColor = 'white', contentClassName = '', currentStep } = props;

  let bgClassName;
  switch (backgroundColor) {
    case 'white':
      bgClassName = 'bg-white';
      break;
    default:
      unreachable(backgroundColor);
  }

  useEffect(() => {
    scrollElementToTop('workflow-modal-children-container');
  }, [currentStep]);

  // 100% viewport height minus 5rem to account for parent's total y-margin
  const maxHeightClassName = 'lg:max-h-[calc(100vh_-_5rem)]';

  return (
    <Modal isOpen={modalOpen} onClose={() => closeModal()}>
      <div className="flex w-full items-center justify-center outline-0 lg:m-10 lg:w-auto">
        <div
          className={classNames(
            'flex h-full w-full flex-col text-moov-blue-500 lg:my-auto lg:h-fit lg:rounded-xl',
            maxHeightClassName,
            bgClassName
          )}
        >
          <WorkflowHeader<TCurrentStep> {...props} />
          <div className={`hide-scrollbar flex-1 overflow-y-auto p-4 lg:p-6 ${contentClassName}`} id="workflow-modal-children-container">
            {children}
          </div>
          <WorkflowButtons {...props} />
        </div>
      </div>
    </Modal>
  );
};

export default WorkflowModal;
