import { Stack, Typography } from '@mui/material';
import { SelectWithOptions } from '../../design/bytes/selectWithOptions';
import { useAppDispatch, useAppSelector } from '../../store';
import { getOfferFilters, getSellerOffers } from '../selectors';
import { ReadableOfferStatus } from '../types';
import { OfferSortBys, setModelFilter, setSortBy, setStatusFilter } from '../slice';
import { spacing } from '../../design/designConstants';
import { toRecord } from '../../state/services';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';

export const SellerOfferFilters = () => {
  const { status, modelId, sortBy } = useAppSelector(getOfferFilters);
  const dispatch = useAppDispatch();
  const offers = useAppSelector(getSellerOffers);

  const uniqueModels = Object.values(toRecord(offers.map((offer) => offer.listing.model)));

  const expireTimeASCLabel = (
    <Stack data-testid="expire-time-asc-label" direction="row" alignItems="center" spacing={spacing.sm}>
      <Typography>Expire Time</Typography>
      <FAIcon icon={icons.chevronUp} />
    </Stack>
  );

  const expireTimeDESCLabel = (
    <Stack data-testid="expire-time-desc-label" direction="row" alignItems="center" spacing={spacing.sm}>
      <Typography>Expire Time</Typography>
      <FAIcon icon={icons.chevronDown} />
    </Stack>
  );

  return (
    <Stack direction="row" px={spacing.xl} spacing={spacing.lg}>
      <SelectWithOptions
        sx={{ minWidth: '240px' }}
        value={modelId}
        options={[{ id: '0', label: 'All Models' }, ...uniqueModels.map((model) => ({ id: model.id.toString(), label: model.name }))]}
        handleChange={(newValue) => {
          dispatch(setModelFilter(newValue));
        }}
      />
      <SelectWithOptions
        sx={{ minWidth: '240px' }}
        value={status}
        renderValue={(value) => {
          if (value === 'all') {
            return 'All Statuses';
          }

          return `Status: ${value}`;
        }}
        options={[
          { id: 'all', label: 'All Statuses' },
          { id: ReadableOfferStatus.PENDING, label: ReadableOfferStatus.PENDING },
          { id: ReadableOfferStatus.ACCEPTED, label: ReadableOfferStatus.ACCEPTED },
          { id: ReadableOfferStatus.DECLINED, label: ReadableOfferStatus.DECLINED },
          { id: ReadableOfferStatus.EXPIRED, label: ReadableOfferStatus.EXPIRED }
        ]}
        handleChange={(newValue) => {
          dispatch(setStatusFilter(newValue));
        }}
      />

      <SelectWithOptions
        sx={{ minWidth: '240px' }}
        value={sortBy}
        renderValue={(value) => {
          if (value === OfferSortBys.EXPIRE_TIME_ASC) return expireTimeASCLabel;
          if (value === OfferSortBys.EXPIRE_TIME_DESC) return expireTimeDESCLabel;
          return `Sort By: ${value}`;
        }}
        options={[
          { id: OfferSortBys.HIGHEST_OFFER, label: OfferSortBys.HIGHEST_OFFER },
          {
            id: OfferSortBys.EXPIRE_TIME_ASC,
            label: expireTimeASCLabel
          },
          {
            id: OfferSortBys.EXPIRE_TIME_DESC,
            label: expireTimeDESCLabel
          }
        ]}
        handleChange={(newValue) => {
          dispatch(setSortBy(newValue));
        }}
      />
    </Stack>
  );
};
